import lazyLoader from 'lib/lazyLoader';

const TeamRegisterLoadable = lazyLoader(
  async () => await import(/* webpackChunkName: "team-register" */ './team-register')
);

const TeamRegister = (props: any) => {
  return <TeamRegisterLoadable {...props} />;
};

export default TeamRegister;
