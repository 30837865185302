type Props = {
  iconColor?: string;
} & JSX.IntrinsicElements['svg'];

export const UsersSolidIcon = (props: Props) => {
  const { iconColor, ...rest } = props;

  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill={iconColor || '#3E5C77'}
        d="M8 3C8 4.65685 6.65685 6 5 6C3.34315 6 2 4.65685 2 3C2 1.34315 3.34315 0 5 0C6.65685 0 8 1.34315 8 3Z"
      />
      <path
        fill={iconColor || '#3E5C77'}
        d="M16 3C16 4.65685 14.6569 6 13 6C11.3431 6 10 4.65685 10 3C10 1.34315 11.3431 0 13 0C14.6569 0 16 1.34315 16 3Z"
      />
      <path
        fill={iconColor || '#3E5C77'}
        d="M11.9291 14C11.9758 13.6734 12 13.3395 12 13C12 11.3648 11.4393 9.86059 10.4998 8.66907C11.2352 8.24355 12.0892 8 13 8C15.7614 8 18 10.2386 18 13V14H11.9291Z"
      />
      <path
        fill={iconColor || '#3E5C77'}
        d="M5 8C7.76142 8 10 10.2386 10 13V14H0V13C0 10.2386 2.23858 8 5 8Z"
      />
    </svg>
  );
};
