import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { DEEP_LINK_URL_HOME_TEAM } from 'helpers/constants';
import detectMobileBrowser from 'helpers/detectMobileBrowser';

import { useLoadingStore } from 'store/loading';

const SuccessPayment: React.FC = () => {
  const navigate = useNavigate();
  const setShowLoading = useLoadingStore((state) => state.setShowLoading);

  const urlSearchParam = new URLSearchParams(location.search);
  const sessionId = urlSearchParam.get('session_id');
  const sessionType = urlSearchParam.get('session_type');

  useEffect(() => {
    setShowLoading(true);
    if (sessionType === 'subs_create') {
      navigate(`/teams/register?session_id=${sessionId}`, { replace: true });
    } else if (sessionType === 'subs_update') {
      if (detectMobileBrowser()) {
        window.location.assign(DEEP_LINK_URL_HOME_TEAM);
      } else {
        navigate('/');
      }
    }

    return () => {
      setShowLoading(false);
    };
  }, []);

  return <div />;
};

export default SuccessPayment;
