import lazyLoader from 'lib/lazyLoader';

const RenewSubscriptionLoadable = lazyLoader(
  async () =>
    await import(/* webpackChunkName: "team-renew-subscription" */ './team-renew-subscription')
);

const RenewSubscription = (props: any) => {
  return <RenewSubscriptionLoadable {...props} />;
};

export default RenewSubscription;
