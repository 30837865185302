import lazyLoader from 'lib/lazyLoader';

const InvitationLoadable = lazyLoader(
  async () => await import(/* webpackChunkName: "invitation" */ './invitation')
);

const Invitation = (props: any) => {
  return <InvitationLoadable {...props} />;
};

export default Invitation;
