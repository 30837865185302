export default {
  // 🇺🇸 Sent
  sent: '送信数',

  // 🇺🇸 Last update time
  lastUpdateTime: '最終更新時間',

  // 🇺🇸 Spesific period
  spesificPeriod: '期間指定',

  // 🇺🇸 The number of sent emails
  numberOfTransmissions: '送信回数',

  // 🇺🇸 The number of boards used
  numberOfBoardsUsed: 'ボード使用数',

  // 🇺🇸 Sender list
  senderList: '送信者一覧',

  // 🇺🇸 Analytic
  analytic: 'アナリティクス',

  // 🇺🇸 Times
  times: '回',

  // 🇺🇸 No applicable data
  noChartDataBoard: '該当するデータがありません'
};
