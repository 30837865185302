import { useEffect, useRef, useState } from 'react';

import { CheckOutlineIcon } from 'components/icons';
import Portal from 'components/portal';

import {
  AttachableDropdownInterface,
  DropdownItemInterface
} from './attachable-dropdown.interface';

function Dropdown({
  items,
  onChoose,
  openDropdown,
  anchorEl,
  placement = 'bottom-start',
  onClose,
  gap = 0,
  itemClassName,
  fullWidth
}: AttachableDropdownInterface<DropdownItemInterface>) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const dropdownEl = useRef<HTMLUListElement>(null);

  const selectItem = (item: DropdownItemInterface) => {
    onChoose?.(item);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsDropdownOpen(!!openDropdown);
    }, 200);
  }, [openDropdown]);

  const getAnchorPosition = () => {
    if (anchorEl && dropdownEl.current) {
      const anchorRect = anchorEl.getBoundingClientRect();
      const gapSize = gap; // constant size to
      const width = fullWidth ? `${anchorEl.offsetWidth}px` : 'auto';

      // this is to handle when the dropdown is on the bottom of the page (it being cropped by the viewport), hence will automatically move the placement to the top of the anchor
      const isOverViewport =
        anchorRect.bottom + dropdownEl.current.clientHeight > window.innerHeight;
      const topOverViewport = `${
        anchorRect.y + window.scrollY - dropdownEl.current.clientHeight + gapSize
      }px`;
      const topPosition = anchorRect.bottom + window.scrollY + gapSize;

      if (placement === 'bottom-end') {
        return {
          top: isOverViewport ? topOverViewport : `${topPosition}px`,
          right: `${window.innerWidth - anchorRect.right}px`,
          width
        };
      }
      if (placement === 'bottom-start') {
        return {
          top: isOverViewport ? topOverViewport : `${topPosition}px`,
          left: `${anchorRect.left}px`,
          width
        };
      }
      if (placement === 'bottom') {
        const centerDropdown = dropdownEl.current.offsetWidth / 2;
        const centerAnchor = anchorRect.width / 2;

        return {
          top: isOverViewport ? topOverViewport : `${topPosition}px`,
          left: `${anchorRect.left + centerAnchor - centerDropdown}px`,
          width
        };
      }
      if (placement === 'top') {
        return {
          top: topOverViewport,
          left: `${anchorRect.left}px`,
          width
        };
      }
    }

    return { bottom: '100%' };
  };

  const itemArr = items.map((item) => {
    const disabledClass = item.disabled ? 'opacity-50' : '';
    const activeClass = item.active ? 'bg-pebble-grey' : '';

    return (
      <li
        key={item.id}
        className={`
        flex
        justify-between
        animate
        justify-left mx-2 flex w-auto flex-row
        content-center items-center rounded-lg p-3
        hover:cursor-pointer hover:bg-pebble-grey ${itemClassName}
        ${disabledClass}
        ${activeClass}`}
        onClick={() => {
          if (item.disabled) return;
          selectItem(item);
        }}
      >
        <div className="w-full">
          {item.icon && <span className="pr-2">{item.icon}</span>}
          <div className="selectable-option text-left">
            <div
              className={`selectable-option__title text-base text-grey-900 ${item.titleClassName}`}
            >
              {item.title}
            </div>
            {item.description && (
              <div
                className={`selectable-option__title text-xs text-grey-500 ${item.descriptionClassName}`}
              >
                {item.description}
              </div>
            )}
          </div>
        </div>

        {item.active && <CheckOutlineIcon className="ml-1 w-[45px]" />}
      </li>
    );
  });

  if (!openDropdown) return null;

  return (
    <Portal>
      {isDropdownOpen && (
        <div className="fixed top-0 left-0 right-0 bottom-0 z-20" onClick={onClose} />
      )}
      <ul
        ref={dropdownEl}
        style={getAnchorPosition()}
        className={`
        animate
        max-h-[500px]
        overflow-y-auto
        ${isDropdownOpen ? 'opacity-1 visible translate-y-1' : 'invisible -translate-y-2 opacity-0'}
        absolute z-20 min-w-max overflow-hidden rounded-lg bg-white py-2 shadow-default`}
      >
        {itemArr}
      </ul>
    </Portal>
  );
}

export default Dropdown;
