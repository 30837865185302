import lazyLoader from 'lib/lazyLoader';

const TeamPlanOnboardingLoadable = lazyLoader(
  async () => await import(/* webpackChunkName: "team-plan-intro" */ './team-plan-onboarding')
);

const TeamPlanOnboarding = (props: any) => {
  return <TeamPlanOnboardingLoadable {...props} />;
};

export default TeamPlanOnboarding;
