import create from 'zustand';

export interface useMobileDrawerStoreInterface {
  showMobileDrawer: boolean;
  setShowMobileDrawer: (isShow: boolean) => void;
}

export const useMobileDrawerStore = create<useMobileDrawerStoreInterface>((set) => ({
  showMobileDrawer: false,
  setShowMobileDrawer: (isShow: boolean) => set({ showMobileDrawer: isShow })
}));
