import create from 'zustand';

export interface useInputFolderNameStoreInterface {
  selectedFolderName: string;
  selectedFolderId: number;
  selectedFolderRole: string;
  selectedFolderAccessRole: string;
  setSelectedFolderName: (folderName: string) => void;
  setSelectedFolderId: (folderId: number) => void;
  setSelectedFolderRole: (folderRole: string) => void;
  setSelectedFolderAccessRole: (folderRole: string) => void;
  showInputFolderName: boolean;
  setShowInputFolderName: (show: boolean) => void;
  allowWriteFolderAndBoard: () => boolean;
}

export const useInputFolderNameStore = create<useInputFolderNameStoreInterface>((set) => ({
  selectedFolderName: '',
  selectedFolderId: 0,
  selectedFolderRole: '',
  selectedFolderAccessRole: '',
  setSelectedFolderName: (folderName) => set(() => ({ selectedFolderName: folderName })),
  setSelectedFolderId: (folderId) => set(() => ({ selectedFolderId: folderId })),
  setSelectedFolderRole: (folderRole) => set(() => ({ selectedFolderRole: folderRole })),
  setSelectedFolderAccessRole: (folderRole) =>
    set(() => ({ selectedFolderAccessRole: folderRole })),
  showInputFolderName: false,
  setShowInputFolderName: (show) => set(() => ({ showInputFolderName: show })),
  allowWriteFolderAndBoard: () => {
    const roleSelected: string = useInputFolderNameStore.getState().selectedFolderRole;
    const folderRoleSelected: string = useInputFolderNameStore.getState().selectedFolderAccessRole;
    if (
      ['performer', 'team_performer', 'can_share'].includes(roleSelected) ||
      ['team_performer'].includes(folderRoleSelected)
    )
      return false;
    return true;
  }
}));
