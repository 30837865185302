import { prepareOptions } from 'api/config';

import { UserPlanCode } from 'helpers/constants';

export interface UserProfile {
  data: {
    id: number;
    email: string;
    name: string;
    plan: UserPlanCode;
    created_at: string;
    updated_at: string;
  };
}

export const getUserProfile = async (): Promise<UserProfile> => {
  return await fetch(`${import.meta.env.VITE_DEV_BASE_URL}/v1/users/profile`, {
    method: 'GET',
    ...prepareOptions()
  }).then(async (res) => {
    if (!res.ok) {
      throw await res.json();
    }
    return await res.json();
  });
};

interface IUpdateUserProfileParam {
  name: string;
  email: string;
}

export const updateUserProfile = async (param: IUpdateUserProfileParam): Promise<UserProfile> => {
  return await fetch(`${import.meta.env.VITE_DEV_BASE_URL}/v1/users/profile`, {
    method: 'PATCH',
    ...prepareOptions({ data: JSON.stringify(param) })
  }).then(async (res) => {
    if (!res.ok) {
      throw await res.json();
    }
    return await res.json();
  });
};

export const deleteUser = async (): Promise<unknown> => {
  return await fetch(`${import.meta.env.VITE_DEV_BASE_URL}/v1/users/profile`, {
    method: 'DELETE',
    ...prepareOptions({})
  }).then(async (res) => {
    if (!res.ok) {
      throw await res.json();
    }
    return await res.json();
  });
};

interface FolderInvitation {
  folder: {
    id: number;
    name: string;
    url_id: string;
  };
  inviter: {
    id: number;
    name: string;
    email: string;
  };
  role: string;
}

interface TeamInvitation {
  team: {
    id: number;
    name: string;
    url_id: string;
  };
  inviter: {
    id: number;
    name: string;
    email: string;
  };
  role: string;
}

interface PendingInvitation {
  data: {
    folder_invitations: FolderInvitation[];
    team_member_invitations: TeamInvitation[];
  };
}

export const getUserPendingInvitations = async (): Promise<PendingInvitation> => {
  return await fetch(`${import.meta.env.VITE_DEV_BASE_URL}/v1/users/pending-invitations`, {
    method: 'GET',
    ...prepareOptions()
  }).then(async (res) => {
    if (!res.ok) {
      throw await res.json();
    }
    return await res.json();
  });
};

export interface UserSurveyQuestion {
  id: number;
  question: string;
  answer?: number | string | null;
  answer_text?: string | null;
  detail: Array<{
    id: number;
    answer: string;
  }>;
}

interface UserSurveyQuestionsResponse {
  data: {
    questions: UserSurveyQuestion[];
    status: 0 | 1 | 2;
  };
}

export const getUserSurveyQuestions = async (): Promise<UserSurveyQuestionsResponse> => {
  return await fetch(`${import.meta.env.VITE_DEV_BASE_URL}/v1/users/survey`, {
    method: 'GET',
    ...prepareOptions()
  }).then(async (res) => {
    if (!res.ok) {
      throw await res.json();
    }
    return await res.json();
  });
};

export interface SubmitSurveyQuestion {
  question_id: number;
  answer: string | number;
}

export interface SaveUserSurveyParam {
  data: SubmitSurveyQuestion[];
  skip: boolean;
}

export interface SaveUserSurveyResponse {
  data: { message: string };
}

export const saveUserSurvey = async (
  param: SaveUserSurveyParam
): Promise<SaveUserSurveyResponse> => {
  return await fetch(`${import.meta.env.VITE_DEV_BASE_URL}/v1/users/survey`, {
    method: 'POST',
    ...prepareOptions({ data: JSON.stringify(param) })
  }).then(async (res) => {
    if (!res.ok) {
      throw await res.json();
    }
    return await res.json();
  });
};

interface UserFlag {
  data: {
    survey_status: number;
    promotion_consent: boolean;
  };
}

export const getUserFlags = async (): Promise<UserFlag> => {
  return await fetch(`${import.meta.env.VITE_DEV_BASE_URL}/v1/users/flag`, {
    method: 'GET',
    ...prepareOptions()
  }).then(async (res) => {
    if (!res.ok) {
      throw await res.json();
    }
    return await res.json();
  });
};

export interface UpdateUserFlagParam {
  promotion_consent: number;
}

export interface UpdateUserFlagResponse {
  data: { message: string };
}

export const updateUserFlag = async (
  param: UpdateUserFlagParam
): Promise<UpdateUserFlagResponse> => {
  return await fetch(`${import.meta.env.VITE_DEV_BASE_URL}/v1/users/flag`, {
    method: 'PATCH',
    ...prepareOptions({ data: JSON.stringify(param) })
  }).then(async (res) => {
    if (!res.ok) {
      throw await res.json();
    }
    return await res.json();
  });
};

export interface UserLastVisitedPage {
  data: {
    user_id: number;
    folder_url_id: string;
    team_url_id: string;
    team_id: number;
  };
}

export const getUserLastVisitedPage = async (): Promise<UserLastVisitedPage> => {
  return await fetch(`${import.meta.env.VITE_DEV_BASE_URL}/v1/users/last-visited-page`, {
    method: 'GET',
    ...prepareOptions()
  }).then(async (res) => {
    if (!res.ok) {
      throw await res.json();
    }
    return await res.json();
  });
};
