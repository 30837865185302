export default {
  // 🇺🇸 Team
  team: 'チーム',
  // 🇺🇸 Others
  others: 'その他',
  // 🇺🇸 Analytic
  analytics: 'アナリティクス',
  // 🇺🇸 Team Management
  teamManagement: 'メンバー管理',
  // 🇺🇸 Team Settings
  teamSettings: 'チーム設定',
  // 🇺🇸 Account
  account: 'アカウント',
  // 🇺🇸 Inquiry
  inquiry: 'お問い合わせ',
  // 🇺🇸 Help Page
  helpPage: 'ヘルプページ',
  // 🇺🇸 Team Plan
  teamPlan: 'チームプラン',
  // 🇺🇸 Template Gallery
  templateGallery: 'テンプレートギャラリー'
};
