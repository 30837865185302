type Props = {
  iconColor?: string;
} & JSX.IntrinsicElements['svg'];

export const FolderOpenOutlineIcon = (props: Props) => {
  const { iconColor, ...rest } = props;

  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill={iconColor || '#3E5C77'}
        d="M7 1L7.70711 0.292893L7.41421 0H7V1ZM9 3L8.29289 3.70711L8.58579 4H9V3ZM14 6C14 6.55228 14.4477 7 15 7C15.5523 7 16 6.55228 16 6H14ZM2 13V3H0V13H2ZM3 2H7V0H3V2ZM6.29289 1.70711L8.29289 3.70711L9.70711 2.29289L7.70711 0.292893L6.29289 1.70711ZM9 4H13V2H9V4ZM13 4C13.5523 4 14 4.44772 14 5H16C16 3.34315 14.6569 2 13 2V4ZM2 3C2 2.44772 2.44772 2 3 2V0C1.34315 0 0 1.34315 0 3H2ZM0 13C0 14.6569 1.34315 16 3 16V14C2.44772 14 2 13.5523 2 13H0ZM14 5V6H16V5H14ZM6 8C6 7.44772 6.44772 7 7 7V5C5.34315 5 4 6.34315 4 8H6ZM7 7H17V5H7V7ZM17 7C17.5523 7 18 7.44772 18 8H20C20 6.34315 18.6569 5 17 5V7ZM18 8V13H20V8H18ZM18 13C18 13.5523 17.5523 14 17 14V16C18.6569 16 20 14.6569 20 13H18ZM17 14H3V16H17V14ZM3 16C4.65685 16 6 14.6569 6 13H4C4 13.5523 3.55228 14 3 14V16ZM6 13V8H4V13H6Z"
      />
    </svg>
  );
};
