type Props = {
  iconColor?: string;
} & JSX.IntrinsicElements['svg'];

export const CloseOutlineIcon = (props: Props) => {
  const { iconColor, strokeWidth, ...rest } = props;

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M6 18L18 6M6 6L18 18"
        stroke={iconColor || '#3E5C77'}
        strokeWidth={strokeWidth || 2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
