import { useQuery } from '@tanstack/react-query';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip
} from 'chart.js';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import Dropdown from 'components/attachable-dropdown';
import { DropdownItemInterface } from 'components/attachable-dropdown/attachable-dropdown.interface';
import { ArrowLeftOutlineIcon, ChevronDownSolidIcon } from 'components/icons';

import { IErrorResponse } from 'api/response.interface';
import { ITeamAnalyticsDetailResponse, getTeamAnalyticsDetail } from 'api/services/team-analytics';

import { roundUp } from 'helpers/number';

import { useAuth } from 'context/AuthContext';

import { useErrorStore } from 'store/error';
import { useLoadingStore } from 'store/loading';

import { BarChartDetailOptions, FilterOptions, SortOptions } from '../analytic.constants';
import {
  filterDateTimeRange,
  getLabelByTypeAndDateFilter,
  getTimeIntervalByType
} from '../analytic.helpers';
import { IChartData, IFilterByDate } from '../analytic.interfaces';
import t from '../analytic.translations';
import BoardIcon from './analytic-board-icon';
import DetailBoardTopMembers from './analytic-board-top-board';
import DetailMemberTopBoards from './analytic-member-top-board';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const defaultChartData = {
  labels: [],
  datasets: [
    {
      data: [],
      borderColor: '',
      backgroundColor: '',
      hoverBackgroundColor: '',
      hoverBorderColor: ''
    }
  ]
};

export default function AnalyticDetail() {
  // get params
  const [searchParams] = useSearchParams();
  const nameUser = searchParams.get('name') || '';
  const typeBoard = searchParams.get('type') || '';
  const qsDate = searchParams.get('date') || 'today';
  const selectedFilter = FilterOptions.find((item) => item.id === qsDate);

  const { boardType, boardDetailId } = useParams();
  if (!boardType) {
    return <></>;
  }

  const navigate = useNavigate();
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [dateBy, setDateBy] = useState<DropdownItemInterface>(
    selectedFilter || { id: 'today', title: '今日' }
  );
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [filterDate, setFilterDate] = useState<IFilterByDate>(filterDateTimeRange(qsDate));
  const [interval, setInterval] = useState<string>(
    getTimeIntervalByType(selectedFilter?.id || '3hour')
  );
  const [chartData, setChartData] = useState<IChartData>(defaultChartData);
  const [maxData, setMaxData] = useState<number>(300);

  const onChangeFilterDate = (data: DropdownItemInterface) => {
    setOpenFilter(false);
    setDateBy(data);
    setFilterDate(filterDateTimeRange(data.id));
    setInterval(getTimeIntervalByType(data.id));
  };

  const { activeTeam } = useAuth();
  const teamUrlId = activeTeam?.urlId;

  const setShowError = useErrorStore((state) => state.setShowError);
  const setShowLoading = useLoadingStore((state) => state.setShowLoading);

  const queryKey =
    boardType === 'people'
      ? 'rq_team_members_detail_analytics'
      : 'rq_team_analytics_detail_analytics';

  const query = useQuery({
    queryKey: [
      queryKey,
      boardType,
      teamUrlId,
      boardDetailId,
      filterDate.startDate,
      filterDate.endDate
    ],
    queryFn: async () => {
      setShowLoading(true);
      const { startDate, endDate } = filterDate;
      if (teamUrlId === undefined || boardDetailId == null) return;
      const analyticsType = boardType === 'people' ? 'members' : 'boards';
      const data = await getTeamAnalyticsDetail(
        teamUrlId,
        startDate,
        endDate,
        boardDetailId,
        analyticsType,
        interval
      );
      return data;
    },
    enabled: !!teamUrlId && !!boardDetailId,
    select: ({ data }: { data: ITeamAnalyticsDetailResponse }) => data,
    onSuccess: (data: ITeamAnalyticsDetailResponse) => onSuccessful(data),
    onError: (error: IErrorResponse<string>) => setShowError(error),
    onSettled: () => setShowLoading(false)
  });

  const createChartData = (data: ITeamAnalyticsDetailResponse): IChartData => {
    const labels = data.analytics.map((item) =>
      getLabelByTypeAndDateFilter(dateBy.id, new Date(item.time))
    );
    const datasets = data.analytics.map((item) => item.count);
    setMaxData(Math.max(...datasets));
    const countDatasets = data.analytics.reduce((acc, item) => acc + item.count, 0);
    let color = '#3E5C77';
    let hoverColor = '#FC8E7C';
    if (countDatasets === 0) {
      datasets.fill(90);
      color = '#ABABAB';
      hoverColor = '#ABABAB';
    }

    return {
      labels,
      datasets: [
        {
          data: datasets,
          maxBarThickness: 40,
          borderColor: color,
          backgroundColor: color,
          hoverBackgroundColor: hoverColor,
          hoverBorderColor: hoverColor
        }
      ]
    };
  };

  const getFilterOptions = (): DropdownItemInterface[] => {
    return FilterOptions.map((item) => {
      return {
        ...item,
        active: item.id === dateBy.id
      };
    });
  };

  const onSuccessful = (data: ITeamAnalyticsDetailResponse) => {
    setChartData(createChartData(data));
  };

  useEffect(() => {
    query.refetch();
  }, [filterDate]);

  return (
    <div className="py-8">
      <div className="justify-between flex mr-16">
        <div className="flex items-center px-9">
          <ArrowLeftOutlineIcon
            className="cursor-pointer"
            iconColor="#3E5C77"
            onClick={() => navigate(-1)}
          />
          {boardType === 'boards' && <BoardIcon boardType={typeBoard} className="ml-6" />}
          <span className="md:ml-7 ml-4 text-lg truncate md:w-full w-24  md:text-2xl  font-bold">
            {nameUser}
          </span>
        </div>
        <div
          className={`relative flex items-center justify-between w-[135px] md:w-44 mr-3 inline-flex w-auto cursor-pointer rounded-xl border-2 hover:border-gray-600 border-grey-300 py-1.5 px-3 md:px-4 md:py-3 text-sm md:text-base text-grey-900 ${
            openFilter && 'border-gray-600'
          }`}
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
            setOpenFilter(!openFilter);
          }}
        >
          <span>{dateBy.title || SortOptions[0].title}</span>
          <ChevronDownSolidIcon
            iconColor="#3E5C77"
            className={`animate ml-2 h-auto ${openFilter ? '-rotate-90' : 'rotate-90'}`}
          />
        </div>
        <Dropdown
          itemClassName="w-52"
          placement="bottom-end"
          anchorEl={anchorEl}
          items={getFilterOptions()}
          openDropdown={openFilter}
          onChoose={(data: any) => onChangeFilterDate(data)}
          onClose={() => setOpenFilter(false)}
        />
      </div>
      <div className="mt-12 flex pl-9 pr-16">
        {boardType === 'people' ? (
          <div className="w-0 break-all pt-1 leading-tight">{t.numberOfTransmissions}</div>
        ) : null}

        <Bar className="ml-9" options={BarChartDetailOptions(roundUp(maxData))} data={chartData} />
      </div>
      {boardType === 'people' ? (
        <DetailMemberTopBoards
          teamUrlId={teamUrlId}
          boardDetailId={boardDetailId}
          filterDate={filterDate}
        />
      ) : (
        <DetailBoardTopMembers
          teamUrlId={teamUrlId}
          boardDetailId={boardDetailId}
          filterDate={filterDate}
        />
      )}
    </div>
  );
}
