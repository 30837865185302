import lazyLoader from 'lib/lazyLoader';

const MainLayoutLoadable = lazyLoader(
  async () => await import(/* webpackChunkName: "main-layout" */ './main-layout')
);

const MainLayout = (props: any) => {
  return <MainLayoutLoadable {...props} />;
};

export default MainLayout;
