import lazyLoader from 'lib/lazyLoader';

const SearchResultLoadable = lazyLoader(
  async () => await import(/* webpackChunkName: "search-result" */ './search-result')
);

const MyAccount = (props: any) => {
  return <SearchResultLoadable {...props} />;
};
export default MyAccount;
