import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Dropdown from 'components/attachable-dropdown';
import Button from 'components/button';
import {
  ArrowLeftOutlineIcon,
  CheckSolidIcon,
  ChevronDownSolidIcon,
  UserGroupSolidIcon
} from 'components/icons';
import Portal from 'components/portal';

import TeamboardLogo from 'assets/images/teamboard-logo.svg';

import { useAuthUserTeams } from 'hooks/useAuthUserTeams';

import { LS_ACTIVE_TEAM_ID } from 'helpers/constants';

import { useAuth } from 'context/AuthContext';
import { Team } from 'context/AuthContext/AuthContext.interface';

import { useMobileDrawerStore } from 'store/mobile-drawer';

import MenuList from 'pages/main-layout/components/menu-list';

function MobileDrawer() {
  const mobileDrawerStore = useMobileDrawerStore();
  const [teamAnchorEl, setTeamAchorEl] = useState<HTMLButtonElement | null>(null);
  const navigate = useNavigate();

  const { setActiveTeam, activeTeam } = useAuth();

  const { userTeams } = useAuthUserTeams();

  const onChangeActiveTeam = (data: Team) => {
    localStorage.setItem(LS_ACTIVE_TEAM_ID, String(data.id));
    navigate({ search: '' }, { replace: true });
    setActiveTeam(data);
    setTeamAchorEl(null);
  };

  const teamListOptions = useMemo(() => {
    if (userTeams?.length) {
      const modifiedTeamListData = userTeams?.map((val) => ({
        ...val,
        id: String(val.id),
        title: (
          <div className="flex min-w-[168px] items-center justify-between">
            <span>{val.name}</span>
            {String(val.id) === `${activeTeam?.id}` && <CheckSolidIcon />}
          </div>
        )
      }));

      return [...modifiedTeamListData];
    }

    return [];
  }, [userTeams, activeTeam?.id]);

  const hasTeam = !!userTeams?.length;

  if (mobileDrawerStore.showMobileDrawer) {
    return (
      <Portal>
        <div className="fixed top-0 left-0 z-10 flex h-full w-full -translate-x-6 animate-drawerSlideIn flex-col items-start justify-start bg-white px-4 pt-8 sm:block lg:hidden">
          <div className=" flex w-full flex-row justify-between">
            <div className="flex flex-row items-center justify-start">
              <ArrowLeftOutlineIcon
                height={20}
                width={20}
                onClick={() => mobileDrawerStore.setShowMobileDrawer(false)}
              />
              <img src={TeamboardLogo} alt="teamboard-logo" className="ml-4 h-[30px]" />
            </div>
            <div>
              <Button
                className="mr-2"
                icon={<UserGroupSolidIcon className="mr-2" />}
                disabled={!hasTeam}
                onClick={(e) => {
                  setTeamAchorEl(e.currentTarget);
                }}
              >
                <span className="max-w-[60px] truncate text-sm sm:max-w-[90px] md:max-w-[200px]">
                  {activeTeam?.id ? activeTeam.name : ''}
                </span>
                {hasTeam && <ChevronDownSolidIcon />}
              </Button>
            </div>
          </div>
          <div className="mt-4 flex w-full flex-col border-t-[1px]">
            <MenuList isMobile onNavClick={() => mobileDrawerStore.setShowMobileDrawer(false)} />
          </div>

          {teamListOptions?.length > 0 && (
            <Dropdown
              anchorEl={teamAnchorEl}
              items={[...teamListOptions]}
              openDropdown={!!teamAnchorEl}
              onChoose={(data) => onChangeActiveTeam(data as unknown as Team)}
              onClose={() => setTeamAchorEl(null)}
              placement="bottom-end"
            />
          )}
        </div>
      </Portal>
    );
  }

  return null;
}

export default MobileDrawer;
