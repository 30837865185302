type Props = {
  iconColor?: string;
} & JSX.IntrinsicElements['svg'];

export const ChartBarSolidIcon = (props: Props) => {
  const { iconColor, ...rest } = props;

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M2.5 11C2.5 10.7239 2.72386 10.5 3 10.5H5C5.27614 10.5 5.5 10.7239 5.5 11V16C5.5 16.2761 5.27614 16.5 5 16.5H3C2.72386 16.5 2.5 16.2761 2.5 16V11ZM8.5 7C8.5 6.72386 8.72386 6.5 9 6.5H11C11.2761 6.5 11.5 6.72386 11.5 7V16C11.5 16.2761 11.2761 16.5 11 16.5H9C8.72386 16.5 8.5 16.2761 8.5 16V7ZM14.5 4C14.5 3.72386 14.7239 3.5 15 3.5H17C17.2761 3.5 17.5 3.72386 17.5 4V16C17.5 16.2761 17.2761 16.5 17 16.5H15C14.7239 16.5 14.5 16.2761 14.5 16V4Z"
        fill={iconColor || '#3E5C77'}
        stroke={iconColor || '#3E5C77'}
      />
    </svg>
  );
};
