import { refreshSession } from 'lib/firebase';

import { COOKIE_ACCESS_TOKEN, LS_TB_UUID } from 'helpers/constants';
import { getCookie, setCookie } from 'helpers/cookie';

import { IPrepareOptions, IRequestOptions } from './request.interface';

const getMandatoryHeaders = () => {
  const token = getCookie(COOKIE_ACCESS_TOKEN);
  return {
    ...(token ? { Authorization: `Bearer ${getCookie(COOKIE_ACCESS_TOKEN)}` } : {}),
    'X-Device-Type': 'web',
    'Accept-Language': 'ja',
    'Tracking-ID': localStorage.getItem(LS_TB_UUID) || ''
  };
};

export const prepareOptions = (params?: IPrepareOptions, headers?: any) => {
  const options: IRequestOptions = {
    headers: {
      ...headers,
      ...getMandatoryHeaders(),
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };
  if (params?.data && typeof params.data === 'string') {
    options.body = params.data;
    options.headers['Content-Type'] = 'application/json';
  }

  return options;
};

export const checkAuth = async (res: any, retry?: () => Promise<any>) => {
  if (res.status === 401) {
    const isSuccess = await refreshSession();

    if (!isSuccess) {
      setCookie(COOKIE_ACCESS_TOKEN, '', 0);
      const nextUrl = window.location.href;
      window.location.href = '/login?next-url=' + nextUrl;
    }
  }
};

export const postFormDataAuth = async (url: string, reqFormData: FormData) => {
  return await fetch(url, {
    method: 'POST',
    headers: { ...getMandatoryHeaders() },
    body: reqFormData
  }).then(async (res) => {
    if (!res.ok) {
      await checkAuth(res);
      throw await res.json();
    }
    return await res.json();
  });
};

export const fetchAuth = async (url: string, method: string, reqBody: any, headers?: any) => {
  return await fetch(url, {
    method,
    ...prepareOptions({ data: JSON.stringify(reqBody) }, headers)
  }).then(async (res) => {
    if (!res.ok) {
      await checkAuth(res);
      throw await res.json();
    }
    return await res.json();
  });
};

export const postJsonAuth = async (url: string, reqBody: any, headers?: any) => {
  return await fetchAuth(url, 'POST', reqBody, headers);
};

export const patchJsonAuth = async (url: string, reqBody: any, headers?: any) => {
  return await fetchAuth(url, 'PATCH', reqBody, headers);
};

export const getWithAuth = async (url: string) => {
  return await fetch(url, {
    method: 'GET',
    headers: { ...getMandatoryHeaders() }
  }).then(async (res) => {
    if (!res.ok) {
      await checkAuth(res);
      throw await res.json();
    }
    return await res.json();
  });
};
