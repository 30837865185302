import lazyLoader from 'lib/lazyLoader';

const UserSurveyLoadable = lazyLoader(
  async () => await import(/* webpackChunkName: "user-survey" */ './user-survey')
);

const UserSurvey = (props: any) => {
  return <UserSurveyLoadable {...props} />;
};

export default UserSurvey;
