import React from 'react';

import googleAnalytics from 'helpers/googleAnalytics';

googleAnalytics.init();

export default function useGoogleInitAnalytics() {
  // send pageview when location changes
  React.useEffect(() => {
    googleAnalytics.sendPageview(window.location.pathname);
  }, [window.location.pathname]);
}

export const useAnalyticsEventTracker = (category: string) => {
  const eventTracker = (action: string, param: any = '') => {
    googleAnalytics.sendEvent({ category, action, value: param });
  };
  return eventTracker;
};
