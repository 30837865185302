import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  AdjustmentsSolidIcon,
  ChartBarSolidIcon,
  ChatAlt2SolidIcon,
  GlobeAltOutlineIcon,
  TemplateSolidIcon,
  UserCircleSolidIcon,
  UserGroupSolidIcon,
  UsersSolidIcon
} from 'components/icons';

import { useAnalyticsEventTracker } from 'hooks/useGoogleAnalytics';

import {
  GA_EVENT_ACTIONS,
  GA_EVENT_CATEGORIES,
  HELP_PAGE_URL,
  INQUIRY_PAGE_URL,
  TEMPLATE_GALLERY_LANDING_PAGE_URL
} from 'helpers/constants';

import { useAuth } from 'context/AuthContext';

import t from './menu-list.translations';

interface MenuListInterface {
  isMobile?: boolean;
  onNavClick?: any;
}

interface MenuItemListInterface {
  type: string;
  label: string;
  url?: string;
  icon?: any;
  hidden?: boolean;
  onClick?: any;
}

function MenuList({ isMobile = false, onNavClick = () => {} }: MenuListInterface) {
  const navigate = useNavigate();
  const { userId, activeTeam } = useAuth();

  const shouldDisplayTeamMenus = activeTeam?.role === 'admin' || activeTeam?.role === 'team_editor';
  const gaTeamSettingsEventTracker = useAnalyticsEventTracker(GA_EVENT_CATEGORIES.TEAM_SETTING);

  const lowerSidebarMenu = useMemo(() => {
    const teamMenus = shouldDisplayTeamMenus
      ? [
          {
            type: 'separator',
            label: t.team,
            hidden: isMobile
          },
          {
            type: 'link',
            label: t.analytics,
            url: '/analytics/people',
            icon: <ChartBarSolidIcon />
          },
          {
            type: 'link',
            label: t.teamManagement,
            url: `/teams/${activeTeam?.urlId}`,
            icon: <UserGroupSolidIcon />,
            onClick: () =>
              gaTeamSettingsEventTracker(
                GA_EVENT_ACTIONS.TEAM_SETTING_TAP_MEMBER_MANAGEMENT,
                userId
              )
          },
          {
            type: 'link',
            label: t.teamSettings,
            hidden: isMobile,
            url: `/teams/${activeTeam?.urlId}/settings`,
            icon: <AdjustmentsSolidIcon />
          }
        ]
      : [];

    const teamPerformerMenu =
      activeTeam?.role === 'team_performer'
        ? [
            {
              type: 'separator',
              label: t.team,
              hidden: isMobile
            },
            {
              type: 'link',
              label: t.teamManagement,
              url: `/teams/${activeTeam?.urlId}`,
              icon: <UserGroupSolidIcon />,
              onClick: () =>
                gaTeamSettingsEventTracker(
                  GA_EVENT_ACTIONS.TEAM_SETTING_TAP_MEMBER_MANAGEMENT,
                  userId
                )
            }
          ]
        : [];

    const userMenus = userId
      ? [
          {
            type: 'link',
            label: t.account,
            hidden: isMobile,
            url: `/accounts/${userId}`,
            icon: <UserCircleSolidIcon />
          }
        ]
      : [];

    return [
      ...teamMenus,
      ...teamPerformerMenu,
      {
        type: 'separator',
        label: t.others,
        hidden: isMobile
      },
      ...userMenus,
      {
        type: 'link',
        label: t.teamPlan,
        hidden: isMobile,
        url: '/teams/onboarding',
        icon: <UsersSolidIcon />
      },
      {
        type: 'link',
        label: t.templateGallery,
        hidden: isMobile,
        url: TEMPLATE_GALLERY_LANDING_PAGE_URL,
        icon: <TemplateSolidIcon />
      },
      {
        type: 'link',
        label: t.inquiry,
        hidden: isMobile,
        url: INQUIRY_PAGE_URL,
        icon: <ChatAlt2SolidIcon />
      },
      {
        type: 'link',
        label: t.helpPage,
        hidden: isMobile,
        url: HELP_PAGE_URL,
        icon: <GlobeAltOutlineIcon />
      }
    ];
  }, [userId, activeTeam]);

  return (
    <div className="flex flex-col items-start">
      {lowerSidebarMenu.map((val: MenuItemListInterface) => {
        if (val.hidden) return null;
        if (val.type === 'separator') {
          return (
            <div
              key={val.label}
              className=" relative flex w-full items-center gap-x-2 py-4 px-2.5 text-sm text-gray-500 after:absolute after:left-0 after:bottom-0 after:right-0 after:border-b after:border-gray-200 after:content-[''] last:after:hidden"
            >
              <span>{val.label}</span>
            </div>
          );
        }

        if (val.type === 'link') {
          return (
            <button
              key={val.label}
              className="relative flex w-full items-center gap-x-2 py-4 px-2.5 text-sm font-semibold after:absolute after:left-0 after:bottom-0 after:right-0 after:border-b after:border-gray-200 after:content-[''] last:after:hidden"
              onClick={() => {
                if (val.onClick) val.onClick();
                if (!val.url) return;
                onNavClick();
                if (val.url.startsWith('http')) {
                  window.open(val.url, '_blank');
                  return;
                }

                navigate(val.url);
              }}
            >
              <span>{val.icon}</span>
              <span>{val.label}</span>
            </button>
          );
        }

        return null;
      })}
    </div>
  );
}

export default MenuList;
