type Props = {
  iconColor?: string;
} & JSX.IntrinsicElements['svg'];

export const SortAscOutlineIcon = (props: Props) => {
  const { iconColor, ...rest } = props;

  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M1 1H14M1 5H10M1 9H7M11 9L15 5M15 5L19 9M15 5V17"
        stroke={iconColor || '#3E5C77'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
