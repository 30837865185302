import lazyLoader from 'lib/lazyLoader';

const EmailConsentSettingsLoadable = lazyLoader(
  async () => await import(/* webpackChunkName: "user-survey" */ './email-consent-settings')
);

const EmailConsentSettings = (props: any) => {
  return <EmailConsentSettingsLoadable {...props} />;
};

export default EmailConsentSettings;
