import { GetFolderDetailResponse, GetFoldersResponse } from 'api/service-shared.interface';

import { InputFolderNameInterface } from 'pages/home/components/input-folder/input-folder.interface';
import { TeamFolderMemberResponse } from 'pages/home/components/popover-folder-access/popover-folder-access.interface';
import { ShareableLinkStatusParam } from 'pages/home/components/sharelink-settings/sharelink.interface';

import { checkAuth, patchJsonAuth, postFormDataAuth, prepareOptions } from '../config';
import { GetFolderBoardsResponse } from './folder-board';

export const getFolders = async (): Promise<GetFoldersResponse> => {
  return await fetch(
    `${import.meta.env.VITE_DEV_BASE_URL}/v1/folders?active=true`,
    prepareOptions()
  ).then(async (res) => {
    if (!res.ok) {
      await checkAuth(res);
      throw await res.json();
    }
    return await res.json();
  });
};

export const getFolderDetail = async (folderUrlId: string): Promise<GetFolderDetailResponse> => {
  return await fetch(
    `${import.meta.env.VITE_DEV_BASE_URL}/v1/folders/${folderUrlId}`,
    prepareOptions()
  ).then(async (res) => {
    if (!res.ok) {
      await checkAuth(res);
      throw await res.json();
    }
    return await res.json();
  });
};

export const getFolderBoards = async (
  boardUrlId: string,
  boardType?: string
): Promise<GetFolderBoardsResponse> => {
  return await fetch(
    `${import.meta.env.VITE_DEV_BASE_URL}/v1/folders/${boardUrlId}/boards?type=${boardType}`,
    prepareOptions()
  ).then(async (res) => {
    if (!res.ok) {
      await checkAuth(res);
      throw await res.json();
    }
    return await res.json();
  });
};

export const createFolderBoard = async (boardUrlId: string, reqFormData: FormData) => {
  return await postFormDataAuth(
    `${import.meta.env.VITE_DEV_BASE_URL}/v1/folders/${boardUrlId}/boards`,
    reqFormData
  );
};

export const createFolder = async (reqData: InputFolderNameInterface) => {
  return await fetch(`${import.meta.env.VITE_DEV_BASE_URL}/v1/folders`, {
    method: 'POST',
    ...prepareOptions({ data: JSON.stringify(reqData) })
  }).then(async (res) => {
    if (!res.ok) {
      await checkAuth(res);
      throw await res.json();
    }
    return await res.json();
  });
};

export const updateFolder = async (folderUrlId: string, reqData: InputFolderNameInterface) => {
  return await fetch(`${import.meta.env.VITE_DEV_BASE_URL}/v1/folders/${folderUrlId}`, {
    method: 'PATCH',
    ...prepareOptions({ data: JSON.stringify(reqData) })
  }).then(async (res) => {
    if (!res.ok) {
      await checkAuth(res);
      throw await res.json();
    }
    return await res.json();
  });
};

export const deleteFolder = async (folderUrlId: string) => {
  return await fetch(`${import.meta.env.VITE_DEV_BASE_URL}/v1/folders/${folderUrlId}`, {
    method: 'DELETE',
    ...prepareOptions({})
  }).then(async (res) => {
    if (!res.ok) {
      await checkAuth(res);
      throw await res.json();
    }
    return await res.json();
  });
};

export const leaveFolder = async (folderUrlId: string) => {
  return await fetch(`${import.meta.env.VITE_DEV_BASE_URL}/v1/folders/${folderUrlId}/exit-folder`, {
    method: 'PATCH',
    ...prepareOptions({})
  }).then(async (res) => {
    if (!res.ok) {
      await checkAuth(res);
      throw await res.json();
    }
    return await res.json();
  });
};

export const updateFolderOrder = async (folderId: string, order: number) => {
  const reqData = {
    order
  };
  return await patchJsonAuth(
    `${import.meta.env.VITE_DEV_BASE_URL}/v1/folders/${folderId}/order`,
    reqData
  );
};

export const cancelShareableLink = async (
  resourceUrlId?: string,
  resource?: 'teams' | 'folders',
  uuid?: string
) => {
  return await fetch(
    `${import.meta.env.VITE_DEV_BASE_URL}/v1/${resource}/${resourceUrlId}/share-link/undo`,
    {
      method: 'POST',
      ...prepareOptions(resource === 'teams' ? { data: JSON.stringify({ id: uuid }) } : {})
    }
  ).then(async (res) => {
    if (!res.ok) {
      await checkAuth(res);
      throw await res.json();
    }
    return await res.json();
  });
};

export const generateShareableLink = async ({
  type,
  resourceId,
  role
}: ShareableLinkStatusParam) => {
  return await fetch(
    `${import.meta.env.VITE_DEV_BASE_URL}/v1/${
      type === 1 ? 'folders' : 'teams'
    }/${resourceId}/share-link`,
    {
      method: 'POST',
      ...prepareOptions({
        data: JSON.stringify({
          role
        })
      })
    }
  ).then(async (res) => {
    if (!res.ok) {
      await checkAuth(res);
      throw await res.json();
    }
    return await res.json();
  });
};

export const getTeamFolderMembers = async (
  teamUrlId: string | undefined,
  folderUrlId: string
): Promise<TeamFolderMemberResponse> => {
  return await fetch(
    `${
      import.meta.env.VITE_DEV_BASE_URL
    }/v1/teams/${teamUrlId}/folders/${folderUrlId}/members?all=true`,
    prepareOptions()
  ).then(async (res) => {
    if (!res.ok) {
      await checkAuth(res);
      throw await res.json();
    }
    return await res.json();
  });
};
