import lazyLoader from 'lib/lazyLoader';

const RegisterLoadable = lazyLoader(
  async () => await import(/* webpackChunkName: "register" */ './register')
);

const Register = (props: any) => {
  return <RegisterLoadable {...props} />;
};

export default Register;
