import React from 'react';

import Button from 'components/button';

import NonTeamUserImage from 'assets/images/non-team-user.svg';

import { DEEP_LINK_URL_LOGIN } from 'helpers/constants';

const NonTeamUserView: React.FC = () => {
  const handleOpenInApp = () => {
    window.location.assign(DEEP_LINK_URL_LOGIN);
  };

  return (
    <div className="flex min-h-screen flex-col items-center justify-center">
      <img src={NonTeamUserImage} width="150px" height="150px" />
      <p className="mt-6 max-w-[320px] text-center">
        チームの管理者または編集者ではない方は、モバイルアプリをお使いください
      </p>
      <Button className="mt-6" type="primary" onClick={handleOpenInApp}>
        モバイルアプリで開く
      </Button>
    </div>
  );
};

export default NonTeamUserView;
