type Props = {
  iconColor?: string;
} & JSX.IntrinsicElements['svg'];

export const AdjustmentsSolidIcon = (props: Props) => {
  const { iconColor, ...rest } = props;

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M3.25036 11.7004L3.5 11.556V11.2676V4C3.5 3.72386 3.72386 3.5 4 3.5C4.27614 3.5 4.5 3.72386 4.5 4V11.2676V11.556L4.74964 11.7004C5.19929 11.9605 5.5 12.4454 5.5 13C5.5 13.5546 5.19929 14.0395 4.74964 14.2996L4.5 14.444V14.7324V16C4.5 16.2761 4.27614 16.5 4 16.5C3.72386 16.5 3.5 16.2761 3.5 16V14.7324V14.444L3.25036 14.2996C2.80071 14.0395 2.5 13.5546 2.5 13C2.5 12.4454 2.80071 11.9605 3.25036 11.7004ZM9.25036 5.70037L9.5 5.55596V5.26756V4C9.5 3.72386 9.72386 3.5 10 3.5C10.2761 3.5 10.5 3.72386 10.5 4V5.26756V5.55596L10.7496 5.70037C11.1993 5.96048 11.5 6.44542 11.5 7C11.5 7.55458 11.1993 8.03952 10.7496 8.29963L10.5 8.44404V8.73244V16C10.5 16.2761 10.2761 16.5 10 16.5C9.72386 16.5 9.5 16.2761 9.5 16V8.73244V8.44404L9.25036 8.29963C8.80071 8.03952 8.5 7.55458 8.5 7C8.5 6.44542 8.80071 5.96048 9.25036 5.70037ZM16.5 11.2676V11.556L16.7496 11.7004C17.1993 11.9605 17.5 12.4454 17.5 13C17.5 13.5546 17.1993 14.0395 16.7496 14.2996L16.5 14.444V14.7324V16C16.5 16.2761 16.2761 16.5 16 16.5C15.7239 16.5 15.5 16.2761 15.5 16V14.7324V14.444L15.2504 14.2996C14.8007 14.0395 14.5 13.5546 14.5 13C14.5 12.4454 14.8007 11.9605 15.2504 11.7004L15.5 11.556V11.2676V4C15.5 3.72386 15.7239 3.5 16 3.5C16.2761 3.5 16.5 3.72386 16.5 4V11.2676Z"
        fill={iconColor || '#3E5C77'}
        stroke={iconColor || '#3E5C77'}
      />
    </svg>
  );
};
