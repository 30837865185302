import { useQuery } from '@tanstack/react-query';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip
} from 'chart.js';
import { MouseEvent, useEffect, useRef, useState } from 'react';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import Dropdown from 'components/attachable-dropdown';
import { DropdownItemInterface } from 'components/attachable-dropdown/attachable-dropdown.interface';
import { ChevronDownSolidIcon } from 'components/icons';

import { IErrorResponse } from 'api/response.interface';
import {
  IAnalyticBoards,
  IAnalyticMembers,
  ITeamAnalyticsResponse,
  getTeamAnalytics
} from 'api/services/team-analytics';

import { useAnalyticsEventTracker } from 'hooks/useGoogleAnalytics';
import useWindowSize from 'hooks/useWindowSize';

import { GA_EVENT_ACTIONS, GA_EVENT_CATEGORIES, MOBILE_THRESHOLD } from 'helpers/constants';
import { dateFormatWithTime } from 'helpers/date';
import detectMobileBrowser from 'helpers/detectMobileBrowser';

import { useAuth } from 'context/AuthContext';

import { useErrorStore } from 'store/error';
import { useLoadingStore } from 'store/loading';

import {
  BarChartEmptyOptions,
  BarChartOptions,
  BoardBarChartOptions,
  FilterOptions,
  IconLabel,
  SortOptions
} from '../analytic.constants';
import { dateTimeRangeToday, filterDateTimeRange, labelTrimmer } from '../analytic.helpers';
import { IChartData, IFilterByDate } from '../analytic.interfaces';
import t from '../analytic.translations';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const defaultChartData = {
  labels: [],
  datasets: [
    {
      data: [],
      maxBarThickness: 40,
      borderColor: '#3E5C77',
      backgroundColor: '#3E5C77',
      hoverBackgroundColor: '#3E5C77',
      hoverBorderColor: '#3E5C77'
    }
  ]
};

const getBarChartOptions = (
  isMobile: boolean,
  chartOptions: {
    scales: any;
    cornerRadius?: number;
    indexAxis?: 'y';
    elements?: { bar: { borderWidth: number } };
    responsive?: boolean;
    maintainAspectRatio?: boolean;
    resizeDelay?: number;
    plugins?: { legend: { display: boolean } };
  }
) => {
  if (isMobile) {
    chartOptions.scales.xAxes.ticks.callback = (value: any) => {
      value = value.toString();
      if (value?.length > 12) {
        return `${value / 1000000000000} T`;
      }
      if (value?.length > 9) {
        return `${value / 1000000000} B`;
      }
      if (value?.length > 6) {
        return `${value / 1000000} M`;
      }
      if (value?.length > 3) {
        return `${value / 1000} K`;
      }
      return value;
    };
  }
  return chartOptions;
};

export default function AnalyticMain() {
  const { boardType } = useParams();

  if (!boardType) {
    return <></>;
  }

  if (boardType === 'people') {
    return <AnalyticMember />;
  } else {
    return <AnalyticBoard />;
  }
}

const AnalyticBoard = () => {
  const { width } = useWindowSize();

  // width > 0 check is important since useWindowSize first render width always 0
  const isMobileView = (width < MOBILE_THRESHOLD && width > 0) || detectMobileBrowser();
  const [searchParams] = useSearchParams();
  const qsDate = searchParams.get('date') || 'today';
  const { pathname } = useLocation();

  const [sortBy, setSortBy] = useState<string>('');
  const [filterDate, setFilterDate] = useState<IFilterByDate>(dateTimeRangeToday());

  const navigate = useNavigate();

  const [chartData, setChartData] = useState<any>(defaultChartData);
  const [updatedDate, setUpdatedDate] = useState('');
  const [emptyChart, setEmptyChart] = useState<boolean>(false);
  const gaAnlyticsEventTracker = useAnalyticsEventTracker(GA_EVENT_CATEGORIES.ANALYTICS);

  const { activeTeam, userId } = useAuth();
  const teamUrlId = activeTeam?.urlId;

  const setShowError = useErrorStore((state) => state.setShowError);
  const setShowLoading = useLoadingStore((state) => state.setShowLoading);

  const query = useQuery({
    queryKey: ['rq_team_board_analytics', teamUrlId],
    queryFn: async () => {
      setShowLoading(true);
      const { startDate, endDate } = filterDate;
      if (teamUrlId === undefined) return;
      const data = await getTeamAnalytics(teamUrlId, startDate, endDate, sortBy, 'boards');
      return data;
    },
    enabled: !!teamUrlId && !!userId,
    select: ({ data }: { data: ITeamAnalyticsResponse<IAnalyticBoards> }) => data,
    onSuccess: (data) => {
      setUpdatedDate(dateFormatWithTime(new Date(data?.updated_at)));
      setEmptyChart(!data?.analytics?.length);
      if (!data?.analytics?.length) {
        // TODO: empty state chart data implementation for next phase and waiting Backend API
        // setChartData({
        //   labels: [`${IconLabel.image} Board 1`, `${IconLabel.file} Board 2`, `${IconLabel.text} Board 3`, `${IconLabel.image} Board 4`, `${IconLabel.file} Board 5`, `${IconLabel.text} Board 6`],
        //   datasets: [
        //     {
        //       data: [600, 600, 600, 600, 600, 600],
        //       maxBarThickness: 40,
        //       borderColor: '#ABABAB',
        //       backgroundColor: '#ABABAB',
        //       hoverBackgroundColor: '#ABABAB',
        //       hoverBorderColor: '#ABABAB'
        //     }
        //   ]
        // });
        return;
      }
      const labels = data.analytics.map((item) => {
        let icon = '';
        switch (item.board.type) {
          case 'image':
            icon = IconLabel.image;
            break;
          case 'file':
            icon = IconLabel.file;
            break;
          case 'text':
            icon = IconLabel.text;
            break;
          default:
            icon = IconLabel.image;
            break;
        }
        return labelTrimmer(`${icon} ${item.board.name}`, width);
      });
      const dataCount = data.analytics.map((item) => item.count);

      setChartData({
        labels,
        datasets: [
          {
            data: dataCount,
            maxBarThickness: 40,
            borderColor: '#3E5C77',
            backgroundColor: '#3E5C77',
            hoverBackgroundColor: '#FC8E7C',
            hoverBorderColor: '#FC8E7C'
          }
        ]
      });
    },
    onError: (error: IErrorResponse<string>) => setShowError(error),
    onSettled: () => setShowLoading(false)
  });

  const chartRef = useRef<any>();
  const onClick = (event: MouseEvent<HTMLCanvasElement>) => {
    const { current: chart } = chartRef;

    if (!chart) {
      return;
    }

    const element = getElementAtEvent(chart, event);
    if (!element?.length) return;
    const { index } = element[0];

    const board = query.data?.analytics[index].board;
    if (board) {
      navigate(
        `/analytics/boards/${board.id}?name=${board.name}&type=${board.type}&date=${qsDate}`
      );
    }
  };

  const onChangeFilterDate = (type: string) => {
    setFilterDate(filterDateTimeRange(type));
    searchParams.set('date', type);
    navigate({ search: searchParams.toString() });
  };

  useEffect(() => {
    query.refetch();
  }, [filterDate, sortBy]);

  useEffect(() => {
    // ga event
    gaAnlyticsEventTracker(GA_EVENT_ACTIONS.ANALYTICS_TAB_BOARD_DISPLAY, userId);

    if (chartRef.current) {
      chartRef.current.options.scales = getBarChartOptions(
        isMobileView,
        BoardBarChartOptions
      ).scales;
      chartRef.current.update();
    }
  }, [isMobileView]);

  useEffect(() => {
    if (qsDate) {
      const filterDate = FilterOptions.find((item) => item.id === qsDate);
      if (filterDate) {
        onChangeFilterDate(qsDate);
      }
    }
  }, [qsDate, pathname]);

  return (
    <div className="analytic-main min-h-screen bg-white py-8 md:py-11">
      <AnalyticFilter onChangeSortBy={setSortBy} onChangeFilterDate={onChangeFilterDate} />
      <div className="mt-2 mr-6 flex justify-end text-sm md:mr-20">
        {t.lastUpdateTime} : {updatedDate}
      </div>
      {!emptyChart ? (
        <>
          <div className="mt-10 mb-4 flex items-center">
            <div className="mr-2 ml-4 h-5 w-8 rounded bg-stone-blue md:ml-28" />
            <span className="text-xs md:text-base text-stone-blue">{t.sent}</span>
          </div>
          <div className="px-4 md:px-16" style={{ height: `${chartData.labels.length * 50}px` }}>
            <Bar options={BoardBarChartOptions} data={chartData} onClick={onClick} ref={chartRef} />
          </div>
        </>
      ) : (
        <div className="text-center my-24 text-grey-400 font-medium">{t.noChartDataBoard}</div>
      )}
    </div>
  );
};

const AnalyticMember = () => {
  const { width } = useWindowSize();
  // width > 0 check is important since useWindowSize first render width always 0
  const isMobileView = width < MOBILE_THRESHOLD && width > 0;
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const qsDate = searchParams.get('date') || 'today';

  const [sortBy, setSortBy] = useState<string>('asc');
  const [filterDate, setFilterDate] = useState<IFilterByDate>(dateTimeRangeToday());

  const navigate = useNavigate();

  const [chartData, setChartData] = useState<IChartData>(defaultChartData);
  const [updatedDate, setUpdatedDate] = useState('');
  const [emptyChart, setEmptyChart] = useState(false);

  const { activeTeam, userId } = useAuth();
  const teamUrlId = activeTeam?.urlId;

  const setShowError = useErrorStore((state) => state.setShowError);
  const setShowLoading = useLoadingStore((state) => state.setShowLoading);
  const gaAnlyticsEventTracker = useAnalyticsEventTracker(GA_EVENT_CATEGORIES.ANALYTICS);

  const query = useQuery({
    queryKey: ['rq_team_member_analytics', teamUrlId],
    queryFn: async () => {
      setShowLoading(true);
      const { startDate, endDate } = filterDate;
      if (teamUrlId === undefined) return;
      const data = await getTeamAnalytics(teamUrlId, startDate, endDate, sortBy, 'members');
      return data;
    },
    enabled: !!teamUrlId && !!userId,
    select: ({ data }: { data: ITeamAnalyticsResponse<IAnalyticMembers> }) => data,
    onSuccess: (data) => {
      setUpdatedDate(dateFormatWithTime(new Date(data?.updated_at)));

      const labels = data.analytics.map((item) => labelTrimmer(item.user.name, width));
      const dataCount = data.analytics.map((item) => item.count);
      // total count of all members
      const totalCount = data.analytics.reduce((acc, item) => acc + item.count, 0);
      let colorBar = '#3E5C77';
      let hoverColorBar = '#FC8E7C';
      setEmptyChart(totalCount === 0);
      if (totalCount === 0) {
        colorBar = '#ABABAB';
        hoverColorBar = '#ABABAB';
        dataCount.fill(600);
      }

      setChartData({
        labels,
        datasets: [
          {
            data: dataCount,
            maxBarThickness: 40,
            borderColor: colorBar,
            backgroundColor: colorBar,
            hoverBackgroundColor: hoverColorBar,
            hoverBorderColor: hoverColorBar
          }
        ]
      });
    },
    onError: (error: IErrorResponse<string>) => setShowError(error),
    onSettled: () => setShowLoading(false)
  });

  const chartRef = useRef<any>();
  const onClick = (event: MouseEvent<HTMLCanvasElement>) => {
    const { current: chart } = chartRef;

    if (!chart) {
      return;
    }

    const element = getElementAtEvent(chart, event);
    if (!element?.length) return;
    const { index } = element[0];

    const user = query.data?.analytics[index].user;

    if (user) {
      navigate(`/analytics/people/${user.id}?name=${user.name}&date=${qsDate}`);
    }
  };

  const onChangeFilterDate = (type: string) => {
    setFilterDate(filterDateTimeRange(type));
    searchParams.set('date', type);
    navigate({ search: searchParams.toString() });
  };

  useEffect(() => {
    query.refetch();
  }, [filterDate, sortBy]);

  useEffect(() => {
    // ga event
    gaAnlyticsEventTracker(GA_EVENT_ACTIONS.ANALYTICS_TAB_MEMBER_DISPLAY, userId);

    if (chartRef.current) {
      chartRef.current.options.scales = getBarChartOptions(
        isMobileView,
        emptyChart ? BarChartEmptyOptions : BarChartOptions
      ).scales;
      chartRef.current.update();
    }
  }, [isMobileView]);

  useEffect(() => {
    if (qsDate) {
      const filterDate = FilterOptions.find((item) => item.id === qsDate);
      if (filterDate) {
        onChangeFilterDate(qsDate);
      }
    }
  }, [pathname]);

  return (
    <div className="analytic-main min-h-screen bg-white py-8 md:py-11">
      <AnalyticFilter
        onChangeSortBy={(e) => {
          setSortBy(e);
          gaAnlyticsEventTracker(GA_EVENT_ACTIONS.ANALYTICS_TAP_SELECT_SORT, userId);
        }}
        onChangeFilterDate={(e) => {
          onChangeFilterDate(e);
          gaAnlyticsEventTracker(GA_EVENT_ACTIONS.ANALYTICS_TAP_SELECT_TERM, userId);
        }}
      />
      <div className="mt-2 mr-6 flex justify-end text-xs md:text-sm md:mr-20">
        {t.lastUpdateTime} : {updatedDate}
      </div>
      <div className="mt-10 mb-4 flex items-center">
        <div className="mr-2 ml-4 h-5 w-8 rounded bg-stone-blue md:ml-28" />
        <span className="text-xs md:text-base text-stone-blue">{t.sent}</span>
      </div>
      <div className="px-4 md:px-16" style={{ height: `${chartData.labels.length * 50}px` }}>
        <Bar
          options={emptyChart ? BarChartEmptyOptions : BarChartOptions}
          onClick={onClick}
          data={chartData}
          ref={chartRef}
        />
      </div>
    </div>
  );
};

interface IAnalyticFilterProps {
  onChangeSortBy: (by: string) => void;
  onChangeFilterDate: (type: string) => void;
}

const AnalyticFilter = (props: IAnalyticFilterProps) => {
  const [openFilter, setOpenFilter] = useState<string>('');
  const [sortBy, setSortBy] = useState<DropdownItemInterface>({ id: 'asc', title: '送信数(降順)' });
  const [dateBy, setDateBy] = useState<DropdownItemInterface>({ id: 'today', title: '今日' });
  const [anchorEl1, setAnchorEl1] = useState<HTMLDivElement | null>(null);
  const [anchorEl2, setAnchorEl2] = useState<HTMLDivElement | null>(null);
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();

  const onChangeFilter = (type: string, data: DropdownItemInterface) => {
    setOpenFilter('');
    if (type === 'sort') {
      setSortBy(data);
      props.onChangeSortBy(data.id);
      return;
    }
    setDateBy(data);
    props.onChangeFilterDate(data.id);
  };

  const getFilterOptions = () => {
    return FilterOptions.map((item) => {
      return {
        ...item,
        active: item.id === dateBy.id
      };
    });
  };

  const getSortOptions = () => {
    return SortOptions.map((item) => {
      return {
        ...item,
        active: item.id === sortBy.id
      };
    });
  };

  useEffect(() => {
    const qsDate = searchParams.get('date');
    if (qsDate) {
      const filterDate = FilterOptions.find((item) => item.id === qsDate);
      if (filterDate) {
        setDateBy(filterDate);
      }
    }
  }, [pathname]);

  return (
    <div className="flex justify-end md:mt-10 md:mr-16">
      <div>
        <div
          className={`relative items-center justify-between w-[135px] md:w-44 mr-6 inline-flex w-auto cursor-pointer rounded-xl hover:border-grey-600 border-2 border-grey-300 px-3 md:px-4 py-1.5 md:py-3 text-xs md:text-base text-grey-900 ${
            openFilter === 'sort' && 'border-grey-600'
          }`}
          onClick={(e) => {
            setAnchorEl1(e.currentTarget);
            setOpenFilter(openFilter === 'sort' ? '' : 'sort');
          }}
        >
          <span>{sortBy.title || SortOptions[0].title}</span>
          <ChevronDownSolidIcon
            iconColor="#3E5C77"
            className={`animate ml-2 h-auto ${openFilter === 'sort' ? '-rotate-90' : 'rotate-90'}`}
          />
        </div>
        <Dropdown
          itemClassName="w-52"
          anchorEl={anchorEl1}
          items={getSortOptions()}
          openDropdown={openFilter === 'sort'}
          onChoose={(data: any) => onChangeFilter('sort', data)}
          onClose={() => setOpenFilter('')}
        />
      </div>
      <div>
        <div
          className={`relative items-center justify-between w-[135px] md:w-44 mr-6 inline-flex w-auto cursor-pointer rounded-xl hover:border-grey-600 border-2 border-grey-300 px-3 md:px-4 py-1.5 md:py-3 text-xs md:text-base text-grey-900 ${
            openFilter === 'date' && 'border-grey-600'
          }`}
          onClick={(e) => {
            setAnchorEl2(e.currentTarget);
            setOpenFilter(openFilter === 'date' ? '' : 'date');
          }}
        >
          <span>{dateBy.title || t.spesificPeriod}</span>
          <ChevronDownSolidIcon
            iconColor="#3E5C77"
            className={`animate ml-2 h-auto ${openFilter === 'date' ? '-rotate-90' : 'rotate-90'}`}
          />
        </div>
        <Dropdown
          itemClassName="w-52"
          anchorEl={anchorEl2}
          items={getFilterOptions()}
          openDropdown={openFilter === 'date'}
          onChoose={(data: any) => onChangeFilter('date', data)}
          onClose={() => setOpenFilter('')}
          placement="bottom-end"
        />
      </div>
    </div>
  );
};
