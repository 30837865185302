export const roundUp = (num: number) => {
  return Math.ceil(num / 50) * 50;
};

export const formatMoney = (number: number, currency = true) => {
  const fmtNumber = number
    .toFixed(0) // always two decimal digits
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'); // use . as a separator
  return fmtNumber + `${currency ? '円' : ''}`;
};
