import create from 'zustand';

import { IErrorResponse } from 'api/response.interface';

export interface useErrorStoreInterface {
  showError: boolean;
  code: string;
  title?: string | undefined;
  message?: string | undefined;
  setShowError: (response: IErrorResponse<string>) => void;
  setCloseError: () => void;
}

export const useErrorStore = create<useErrorStoreInterface>((set) => ({
  showError: false,
  code: '',
  title: undefined,
  message: undefined,
  setShowError: ({ errors }: IErrorResponse<string>) => {
    let errorCode = '';
    let errorTitle: string | undefined;
    let errorMessage: string | undefined;
    let showError = false;

    if (errors?.length > 0) {
      const error = errors[0];
      errorCode = error.code;
      errorTitle = error.title;
      errorMessage = error.message;
      showError = true;
    }

    return set(() => ({
      showError,
      code: errorCode,
      title: errorTitle,
      message: errorMessage
    }));
  },
  setCloseError: () =>
    set(() => ({
      showError: false,
      code: '',
      message: undefined
    }))
}));
