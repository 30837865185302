export default {
  // close
  close: '閉じる',
  contactUs: 'お問い合わせ',
  title: 'このチームは使用できません',
  adminSubtitle: (
    <>
      チームプランの契約が終了しているため、チーム機能を利用できません。
      <br />
      ご利用いただくには、お問い合わせの上再契約いただく必要があります。
    </>
  ),
  memberSubtitle:
    'チームプランの契約が終了しているため、チーム機能を利用できません。プランは管理者権限を持つユーザーのみ変更できます。'
};
