import lazyLoader from 'lib/lazyLoader';

const SubscriptionLoadable = lazyLoader(
  async () => await import(/* webpackChunkName: "pro-subscription" */ './pro-subscription')
);

const Subscription = (props: any) => {
  return <SubscriptionLoadable {...props} />;
};

export default Subscription;
