import { useQuery } from '@tanstack/react-query';
import { useParams, useSearchParams } from 'react-router-dom';

import { IErrorResponse } from 'api/response.interface';
import { getTeamList } from 'api/services/team';

import { LS_ACTIVE_TEAM_ID, RQ_TEAM_LIST } from 'helpers/constants';

import { useAuth } from 'context/AuthContext';
import { Team } from 'context/AuthContext/AuthContext.interface';

import { useErrorStore } from 'store/error';

export const useAuthUserTeams = () => {
  const { userId, setActiveTeam } = useAuth();
  const setShowError = useErrorStore((state) => state.setShowError);
  const { teamId: teamUrlId } = useParams();
  const [searchParams] = useSearchParams();
  const qsTeamUrlId = searchParams.get('team_url_id');

  const { data, isLoading, isFetched } = useQuery({
    queryKey: RQ_TEAM_LIST(userId ?? 0),
    queryFn: getTeamList,
    enabled: !!userId,
    refetchOnWindowFocus: false,
    select: ({ data }) => {
      return data.map(
        (val) =>
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          ({
            id: val.id,
            urlId: val.url_id,
            ownerId: val.owner_id,
            name: val.name,
            role: val.role,
            on_cancel: val.on_cancel,
            on_upgrade: val.on_upgrade,
            subscription_period: val.subscription_period,
            subscription_state: val.subscription_state
          } as Team)
      );
    },
    onSuccess: (data) => {
      const lsActiveTeamId = localStorage.getItem(LS_ACTIVE_TEAM_ID);
      const lastActiveTeam = data.find((val) => {
        const filterTeamUrlId = qsTeamUrlId || teamUrlId;
        if (filterTeamUrlId) {
          return String(val.urlId) === filterTeamUrlId;
        }

        return String(val.id) === lsActiveTeamId;
      });

      if (lastActiveTeam) {
        setActiveTeam(lastActiveTeam);
      } else {
        localStorage.removeItem(LS_ACTIVE_TEAM_ID);
      }
    },
    onError: (error: IErrorResponse<string>) => setShowError(error)
  });

  return {
    userTeams: data,
    isLoading,
    isFetched
  };
};
