const toMatch = [
  /Android/i,
  /webOS/i,
  /iPhone/i,
  /iPad/i,
  /iPod/i,
  /BlackBerry/i,
  /Windows Phone/i
];

const detectMobileBrowser = () => {
  const ipadOrMacbook = navigator.userAgent.match(/Mac/i);

  return (
    (ipadOrMacbook && navigator.maxTouchPoints !== 0) ||
    toMatch.some((toMatchItem) => navigator.userAgent.match(toMatchItem))
  );
};

export default detectMobileBrowser;
