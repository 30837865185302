import { AuthErrorCodes } from 'firebase/auth';
import { useMemo } from 'react';

import Button from 'components/button';
import { ExclamationCircleSolidIcon } from 'components/icons';
import Modal from 'components/modal';

import t from './auth.translations';

export default function AuthModal({
  showModal,
  closeModal,
  errorCode
}: {
  showModal: boolean;
  closeModal: () => void;
  errorCode: string;
}) {
  const errorType = useMemo(() => {
    if (errorCode === AuthErrorCodes.INVALID_OOB_CODE) {
      return 'invalid';
    }

    if (errorCode === AuthErrorCodes.EXPIRED_OOB_CODE) {
      return 'expired';
    }

    return 'cross_platform';
  }, []);

  return (
    <Modal
      open={showModal}
      onClose={closeModal}
      wrapperProps={{
        className: '!rounded-[20px] px-[24px] pb-[24px] w-full max-w-[323px] md:max-w-[400px]'
      }}
      hideCloseIcon
    >
      <div className="box-border w-full text-center">
        <div className="flex justify-center mb-[16px]">
          <ExclamationCircleSolidIcon />
        </div>
        <div className="text-center font-bold">{t.title[errorType]}</div>
        <div className="mt-2 text-center">{t.subTitle[errorType]}</div>
        <div className="mt-6 flex justify-center">
          <Button onClick={closeModal} type="primary" className="py-[13px] px-[40px]">
            {t.close}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
