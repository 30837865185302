import Button from 'components/button';
import Modal from 'components/modal';

import { useAuth } from 'context/AuthContext';

import t from './team-payment-expired.translations';

export default function TeamPaymentExpiredModal({
  showModal,
  closeModal
}: {
  showModal: boolean;
  closeModal: () => void;
}) {
  const { activeTeam } = useAuth();

  const isAdmin = activeTeam?.role === 'admin';

  const handleClickContact = () => window.location.assign('https://form.teamboard.jp');

  return (
    <Modal
      open={showModal}
      onClose={closeModal}
      wrapperProps={{
        className: 'rounded-[20px] px-[24px] pb-[24px] w-full max-w-[323px] md:max-w-[400px]'
      }}
      hideCloseIcon
    >
      <div className="box-border w-full text-center">
        <div className="font-bold">{t.title}</div>
        <div className="mt-2 text-sm text-gray-700 mb-8">
          {isAdmin ? t.adminSubtitle : t.memberSubtitle}
        </div>
        <div className="mt-6 flex justify-center">
          {isAdmin ? (
            <>
              <Button onClick={closeModal} className="py-2 px-4 w-full">
                {t.close}
              </Button>
              <Button
                onClick={handleClickContact}
                type="primary"
                className="py-[8px] px-4 w-full ml-4"
              >
                {t.contactUs}
              </Button>
            </>
          ) : (
            <Button onClick={closeModal} className="py-2 px-4 w-full">
              {t.close}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
}
