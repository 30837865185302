type Props = {
  iconColor?: string;
} & JSX.IntrinsicElements['svg'];

export const SortDescOutlineIcon = (props: Props) => {
  const { iconColor, ...rest } = props;

  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M1 1H14M1 5H10M1 9H10M15 5V17M15 17L11 13M15 17L19 13"
        stroke={iconColor || '#3E5C77'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
