import { useQueries, useQuery } from '@tanstack/react-query';
import { createContext, useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { SubFolder, normalizeBoardResponse } from 'types/board';
import { normalizeFolderResponse } from 'types/folder';

import { IErrorResponse } from 'api/response.interface';
import { FolderResponse } from 'api/service-shared.interface';
import { getBoardInSubFolder } from 'api/services/folder-board';
import { getFolderBoards, getFolders } from 'api/services/folders';
import { getTeamFolders } from 'api/services/team-folders';

import { useAuthUserTeams } from 'hooks/useAuthUserTeams';

import {
  HASH_NEW_FOLDER,
  LS_ACTIVE_TEAM_ID,
  QS_SUBFOLDER_URL_ID_KEY,
  RQ_FOLDER_BOARD,
  RQ_PERSONAL_FOLDERS,
  RQ_TEAM_FOLDERS
} from 'helpers/constants';

import { useAuth } from 'context/AuthContext';

import { useErrorStore } from 'store/error';
import { useInputFolderNameStore } from 'store/folder';
import { useGlobalFolderListStore } from 'store/global-folder-list';
import { useLoadingStore } from 'store/loading';
import { useInputSubFolderNameStore } from 'store/sub-folder';

interface FolderBoardContextValue {
  folderBoards: FolderBoard[] | undefined;
  refetchBoards?: any;
}

interface FolderBoard {
  urlId: string;
  type: string;
  text: string;
  folderId: number;
  order: number;
  creatorId: number;
  maxLayerReached: boolean;
}

export const FolderBoardContext = createContext<FolderBoardContextValue>({
  folderBoards: undefined
});

export const FolderBoardContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { folderUrlId: paramFolderUrlId = '' } = useParams();
  const [searchParams] = useSearchParams();
  const subFolderQueryParam = searchParams.get(QS_SUBFOLDER_URL_ID_KEY);
  const subFolderUrlId = subFolderQueryParam?.split(',').pop();
  const setShowLoading = useLoadingStore((s) => s.setShowLoading);
  const setShowError = useErrorStore((state) => state.setShowError);

  const folderUrlId = paramFolderUrlId || searchParams.get('folderUrl') || '';

  const navigate = useNavigate();

  const { activeTeam, setActiveTeam, isFetched: isAuthFetched } = useAuth();

  const folderNameStore = useInputFolderNameStore();
  const subFolderNameStore = useInputSubFolderNameStore();
  const concatFolders = useGlobalFolderListStore((state) => state.concatFolders);
  const concatSubFolders = useGlobalFolderListStore((state) => state.concatSubFolders);

  const { pathname, hash } = useLocation();

  const isCreatingNewFolder = hash.includes(HASH_NEW_FOLDER);

  const isHomePage =
    !pathname.includes('analytics') &&
    !pathname.includes('search-result') &&
    !pathname.includes('/teams/onboarding');

  const { userTeams } = useAuthUserTeams();

  const {
    data: personalFolders,
    isFetching: isIndividualFoldersFetching,
    isFetched: isIndividualFoldersFetched
  } = useQuery({
    enabled: isAuthFetched && !isCreatingNewFolder,
    queryKey: RQ_PERSONAL_FOLDERS,
    queryFn: getFolders,
    select: ({ data }) => data,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const folders = data.map(normalizeFolderResponse);
      concatFolders(folders, undefined);
    },
    onError: (error: IErrorResponse<string>) => setShowError(error)
  });

  // FOR SIDEBAR
  const useQueriesResult = useQueries({
    queries:
      userTeams?.map((team) => ({
        queryKey: RQ_TEAM_FOLDERS(team.urlId),
        queryFn: getTeamFolders(team.urlId ?? ''),
        enabled: isAuthFetched && !isCreatingNewFolder,
        refetchOnWindowFocus: false,
        // useQueries somehow only able to returns any
        onSuccess: (data: { data: FolderResponse[] }) => {
          const folders = data.data.map(normalizeFolderResponse);
          concatFolders(folders, team.urlId);
        }
      })) ?? []
  });

  const isAllTeamFolderListFetched =
    useQueriesResult.filter((uqr) => uqr.isFetched)?.length === useQueriesResult?.length;
  const mergedTeamFolders = isAllTeamFolderListFetched
    ? useQueriesResult.reduce<FolderResponse[]>(
        (curr, folderResponse) => curr.concat(folderResponse.data?.data ?? []),
        []
      )
    : undefined;

  /**
   * Map of Folder List. Grouped by "personal" and [teamUrlId]s
   */
  const folderListMap = useMemo(() => {
    const teamUrlIds = userTeams?.map((ut) => ut.urlId) ?? [];

    const objectMap: Record<string, FolderResponse[] | undefined> = { personal: personalFolders };

    if (isAllTeamFolderListFetched) {
      teamUrlIds.forEach((tuid, teamIdx) => {
        const innerFolders = useQueriesResult[teamIdx].data?.data;

        const sortedInnerFolders = (structuredClone(innerFolders ?? []) as FolderResponse[]).sort(
          (f1: any, f2: any) => (f1.order < f2.order ? -1 : 1)
        );

        objectMap[tuid] = sortedInnerFolders;
      });
    }

    return objectMap;
  }, [isAllTeamFolderListFetched, userTeams, useQueriesResult]);

  const {
    data: folderBoards,
    isFetched: isBoardsFetched,
    refetch: refetchBoards
  } = useQuery({
    enabled:
      isAuthFetched &&
      isHomePage &&
      !!folderUrlId &&
      !!personalFolders &&
      !!mergedTeamFolders &&
      !isCreatingNewFolder,
    queryKey: RQ_FOLDER_BOARD({
      folderUrlId,
      subFolderUrlId,
      activeTeamUrlId: activeTeam?.urlId
    }),
    queryFn: async () => {
      if (subFolderUrlId) {
        return await getBoardInSubFolder(subFolderUrlId, 'all');
      }

      return await getFolderBoards(folderUrlId, 'all');
    },
    select: ({ data }) => data.map(normalizeBoardResponse),
    onSuccess: (boards) => {
      const subFolders = boards.filter((b): b is SubFolder => b.type === 'subfolder');
      concatSubFolders(subFolders, folderUrlId);
    },
    refetchOnWindowFocus: false,
    retry: 0
  });

  useEffect(() => {
    setShowLoading(isIndividualFoldersFetching);
  }, [isIndividualFoldersFetching]);

  /**
   * Handles redirections when folder url exists within the url,
   * or pick the first folder if any.
   *
   * The order of the default picked first folder is taken from individual -> team (if any)
   */
  useEffect(() => {
    if (!isHomePage) return;
    if (isCreatingNewFolder) return;

    const isOnActiveTeam = !!activeTeam?.id || localStorage.getItem(LS_ACTIVE_TEAM_ID);

    if (isAllTeamFolderListFetched && isIndividualFoldersFetched) {
      const sortedPersonalFolders = (
        structuredClone(personalFolders ?? []) as FolderResponse[]
      ).sort((f1: any, f2: any) => (f1.order < f2.order ? -1 : 1));
      const sortedMergedTeamFolders = (
        structuredClone(mergedTeamFolders ?? []) as FolderResponse[]
      ).sort((f1: any, f2: any) => (f1.order < f2.order ? -1 : 1));

      const firstPersonalFolder = sortedPersonalFolders[0];
      const firstTeamFolder = sortedMergedTeamFolders[0];

      const mergedFolderList = [];

      if (sortedPersonalFolders) {
        mergedFolderList.push(...sortedPersonalFolders);
      }

      if (sortedMergedTeamFolders) {
        mergedFolderList.push(...sortedMergedTeamFolders);
      }

      /**
       * When no specific folder url id is defined, but active team is selected
       */
      if (!folderUrlId && isOnActiveTeam && activeTeam) {
        const activeTeamFirstFolder = folderListMap[activeTeam.urlId]?.[0];

        if (activeTeamFirstFolder?.url_id) {
          navigate(`/${activeTeamFirstFolder.url_id}`);
          return;
        }

        navigate('/');
        return;
      }

      /**
       * When no specific folder url id is defined, and no active team is selected
       */
      if (!folderUrlId && !isOnActiveTeam) {
        if (firstPersonalFolder) {
          folderNameStore.setSelectedFolderId(firstPersonalFolder.id);
          folderNameStore.setSelectedFolderName(firstPersonalFolder.name);

          navigate(`/${firstPersonalFolder.url_id}`);
          return;
        }

        navigate('/');
        return;
      }

      if (!subFolderUrlId) {
        subFolderNameStore.setSelectedSubFolderId(0);
        subFolderNameStore.setSelectedSubFolderName('');
      }

      if (folderUrlId) {
        const selectedFolderDetail = mergedFolderList.find(
          (v: { url_id: string }) => v.url_id === folderUrlId
        );

        if (selectedFolderDetail) {
          // determine which team/personal does the folder belongs to
          let selectedPersonalOrTeamKey: string | undefined;

          for (const personalOrTeamKey in folderListMap) {
            if (
              folderListMap[personalOrTeamKey]
                ?.map((fl) => fl.url_id)
                .includes(selectedFolderDetail.url_id)
            ) {
              selectedPersonalOrTeamKey = personalOrTeamKey;
            }
          }

          folderNameStore.setSelectedFolderId(selectedFolderDetail.id);
          folderNameStore.setSelectedFolderName(selectedFolderDetail.name);

          if (selectedPersonalOrTeamKey === 'personal') {
            setActiveTeam(null);
          }

          if (selectedPersonalOrTeamKey && selectedPersonalOrTeamKey !== 'personal') {
            const selectedTeam = userTeams?.filter(
              (ut) => ut.urlId === selectedPersonalOrTeamKey
            )[0];

            if (selectedTeam) {
              setActiveTeam(selectedTeam);
            }
          }

          /**
           * when subfolderUrlId is defined but boards not exists,
           * most probably there is no subfolder in the first place
           * or it has been deleted.
           *
           * on such case, navigate the parent subfolder/parent folder
           */
          if (subFolderUrlId && isBoardsFetched && !folderBoards) {
            const subfolderSegments = subFolderQueryParam?.split(',') ?? [];

            if (subfolderSegments?.length > 1) {
              navigate(
                `/${folderUrlId}?${QS_SUBFOLDER_URL_ID_KEY}=${subfolderSegments
                  .slice(0, subfolderSegments?.length - 1)
                  .join(',')}`
              );
              return;
            }

            navigate(`/${folderUrlId}`);
          }
          return;
        }

        if (isOnActiveTeam && firstTeamFolder) {
          folderNameStore.setSelectedFolderId(firstTeamFolder.id);
          folderNameStore.setSelectedFolderName(firstTeamFolder.name);

          navigate(`/${firstTeamFolder.url_id}`);
          return;
        }

        if (!isOnActiveTeam && firstPersonalFolder) {
          folderNameStore.setSelectedFolderId(firstPersonalFolder.id);
          folderNameStore.setSelectedFolderName(firstPersonalFolder.name);

          navigate(`/${firstPersonalFolder?.url_id}`);
        }
      }
    }
  }, [
    folderUrlId,
    isAllTeamFolderListFetched,
    isIndividualFoldersFetched,
    isHomePage,
    activeTeam,
    isBoardsFetched
  ]);

  return (
    <FolderBoardContext.Provider
      value={{
        folderBoards,
        refetchBoards
      }}
    >
      {children}
    </FolderBoardContext.Provider>
  );
};
