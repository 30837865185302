import { useEffect, useState } from 'react';

import { SortAscOutlineIcon, SortDescOutlineIcon } from 'components/icons';

import { ColumnInterface, TableInterface } from './table.interface';

function Table(props: TableInterface) {
  const [columns, setColumns] = useState<ColumnInterface[]>([]);
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    setColumns(props.columns);
    setData(props.data);
  }, [props.columns, props.data]);

  const onSort = (columnData: ColumnInterface, index: number) => {
    const cloneColumns = structuredClone(columns);
    cloneColumns[index].ascSort = !columnData.ascSort;
    setColumns(cloneColumns);

    if (columnData.onSort) {
      columnData.onSort(columnData);
      return;
    }

    const cloneData = structuredClone(data);
    cloneData.sort((a: any, b: any) => {
      const valA = a[columnData.value].toUpperCase(); // ignore upper and lowercase
      const valB = b[columnData.value].toUpperCase(); // ignore upper and lowercase
      if (valA < valB) {
        return cloneColumns[index].ascSort ? -1 : 1;
      }
      if (valA > valB) {
        return cloneColumns[index].ascSort ? 1 : -1;
      }

      return 0;
    });
    setData(cloneData);
  };

  const renderBodyColumn = (columnData: any, rowData: any, index?: number) => {
    if (columnData?.render) {
      return columnData.render(rowData, index);
    }
    return rowData[columnData.value];
  };

  return (
    <div
      className={`border-collapse rounded-lg bg-white w-full${
        props?.noBorder ? '' : ' border border-grey-200'
      } ${props?.className}`}
    >
      <table className="w-full border-collapse" border={0} cellSpacing={0} cellPadding={0}>
        <thead>
          <tr>
            {columns.map((columnData, index) => (
              <th
                className={`${
                  props?.noBorder ? '' : 'first:rounded-tl-[8px] last:rounded-tr-[8px]'
                }  px-2 py-2.5 md:px-4 md:py-2  bg-grey-100 text-left text-stone-blue  ${
                  columnData.colClassName || ''
                }`}
                key={index}
              >
                {columnData.title}
                {columnData.canSort && (
                  <span className="ml-2 cursor-pointer" onClick={() => onSort(columnData, index)}>
                    {columnData.ascSort ? (
                      <SortAscOutlineIcon className="w-3" iconColor="#3E5C77" />
                    ) : (
                      <SortDescOutlineIcon className="w-3" iconColor="#3E5C77" />
                    )}
                  </span>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.length ? (
            data.map((rowData, iRow) => (
              <tr key={iRow} className={props?.noBorder ? '' : ' border-t border-grey-200'}>
                {columns.map((columnData, iColumn) => (
                  <td
                    className={`px-2 py-2.5 md:px-4 md:py-3 ${columnData.cellClassName || ''}`}
                    key={iColumn}
                  >
                    {renderBodyColumn(columnData, rowData, iRow)}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan={columns?.length}
                className="px-4 py-3 text-center"
                style={{ width: 900 }}
              >
                {props.renderEmpty || 'No result'}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
