export default {
  // close
  close: '閉じる',

  title: {
    // Invalid login link
    invalid: 'ログインリンクが不正です。',
    // Your login link has expired
    expired: 'ログインリンクが期限切れです。',
    // It seems that you logged in in a different environment than the terminal you tried to log in to.
    cross_platform: 'ログインに失敗しました。'
  },

  subTitle: {
    // Please resend the login link and try again
    invalid: 'ログインリンクを再送し、お試しください。',
    // Please try with the latest login link
    expired: '最新のログインリンクでお試しください。',
    // Please press the login link again on the terminal you tried to login.
    cross_platform: 'ログインしようとした端末で再度ログインリンクを押してください。'
  }
};
