import { CloseOutlineIcon } from 'components/icons';
import Portal from 'components/portal';

import { ModalInterface } from './modal.interface';

function Modal({
  open,
  onClose,
  hideCloseIcon = false,
  wrapperProps,
  renderTopRight,
  children,
  rounded = false
}: ModalInterface) {
  if (!open) return null;

  return (
    <Portal>
      <div
        className={`fixed top-0 left-0 z-20 h-full w-full bg-grey-600 bg-opacity-60 transition-opacity overflow-hidden ${
          open ? 'opacity-100' : 'opacity-0'
        }`}
        onClick={onClose}
      />
      <div
        className={`fixed top-2/4 left-2/4 !-translate-x-1/2 !-translate-y-1/2 z-20 animate-slideIn rounded-[${
          rounded ? '30px' : '8px'
        }] bg-white p-4 ${wrapperProps?.className} ${rounded ? 'pb-[30px]' : ''} ${
          hideCloseIcon ? 'py-[32px]' : ''
        }`}
      >
        <div
          className={`flex items-center justify-between ${
            !hideCloseIcon || renderTopRight ? 'mb-6' : ''
          }`}
        >
          {!hideCloseIcon && (
            <button onClick={() => onClose()}>
              <CloseOutlineIcon className="cursor-pointer" iconColor="#3E5C77" />
            </button>
          )}
          {renderTopRight}
        </div>
        {children}
      </div>
    </Portal>
  );
}

export default Modal;
