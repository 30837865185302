import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useEffect } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import Errors from 'components/global-error/error';
import Loading from 'components/loading';
import MobileDrawer from 'components/mobile-drawer';
import Notification from 'components/notification';
import ProtectedRoute from 'components/protected-route';
import Snackbar from 'components/snackbar';

import useGoogleInitAnalytics from 'hooks/useGoogleAnalytics';

import { LS_TB_UUID } from 'helpers/constants';

import { AuthContextProvider } from 'context/AuthContext';
import { FolderBoardContextProvider } from 'context/FolderBoardContext';

import Analytic from 'pages/analytic';
import AnalyticDetail from 'pages/analytic/components/analytic-detail';
import AnalyticMain from 'pages/analytic/components/analytic-main';
import Auth from 'pages/auth';
import EmailConsentSettings from 'pages/email-consent-settings';
import Home from 'pages/home';
import Invitation from 'pages/invitation';
import InvitationShare from 'pages/invitation-share';
import Login from 'pages/login';
import MainLayout from 'pages/main-layout';
import MyAccount from 'pages/my-account';
import NotFound from 'pages/not-found';
import FailedPayment from 'pages/payment/failed';
import SuccessPayment from 'pages/payment/success';
import ProSubscription from 'pages/pro-subscription';
import Register from 'pages/register';
import SearchResult from 'pages/search-result';
import TeamMemberManagement from 'pages/team-member-management';
import TeamPlanOnboarding from 'pages/team-plan-onboarding';
import TeamRegister from 'pages/team-register';
import TeamRenewSubscription from 'pages/team-renew-subscription';
import TeamSettings from 'pages/team-settings';
import TeamSubscription from 'pages/team-subscription';
import UserSurvey from 'pages/user-survey';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3
    }
  }
});

function App() {
  useGoogleInitAnalytics();

  let element: any[] = [
    {
      path: '/',
      element: <Home />,
      protected: true,
      useMainLayout: true,
      mobileUnsupported: true,
      useMobileDrawer: true,
      children: [
        {
          path: ':folderUrlId',
          element: <Home />
        }
      ]
    },
    { path: 'auth', element: <Auth /> },
    { path: 'not-found', element: <NotFound /> },
    { path: 'login', element: <Login /> },
    { path: 'register', element: <Register /> },
    { path: 'invitation', element: <Invitation />, protected: false },
    { path: 'invitation-share', element: <InvitationShare />, protected: false },
    { path: 'accounts/email-consent', element: <EmailConsentSettings />, protected: true },
    { path: 'accounts/:userId', element: <MyAccount />, protected: true },
    { path: 'pro-subscription', element: <ProSubscription />, protected: false },
    {
      path: 'teams/onboarding',
      element: <TeamPlanOnboarding />,
      protected: true,
      useMainLayout: true
    },
    { path: 'teams/subscription', element: <TeamSubscription />, protected: true },
    {
      path: 'teams/:teamId/renewal/subscription',
      element: <TeamRenewSubscription />,
      protected: true
    },
    { path: 'teams/register', element: <TeamRegister />, protected: true },
    {
      path: 'teams/:teamId',
      element: <TeamMemberManagement />,
      protected: true,
      useMobileDrawer: true
    },
    {
      path: 'teams/:teamId/settings',
      element: <TeamSettings />,
      protected: true,
      useMobileDrawer: true
    },
    {
      path: 'analytics',
      element: <Analytic />,
      protected: true,
      useMainLayout: true,
      useMobileDrawer: true,
      children: [
        {
          path: ':boardType',
          element: <AnalyticMain />
        },
        {
          path: ':boardType/:boardDetailId',
          element: <AnalyticDetail />
        }
      ]
    },
    { path: 'payment/success', element: <SuccessPayment />, protected: true },
    { path: 'payment/failed', element: <FailedPayment />, protected: true },
    { path: 'user-survey', element: <UserSurvey />, protected: true },
    {
      path: 'search-result',
      element: <SearchResult />,
      protected: true,
      useMainLayout: true
    }
  ];

  element = element.map((data) => {
    let _data = data;

    if (_data.useMainLayout) {
      _data = {
        ..._data,
        ...{
          element: (
            <FolderBoardContextProvider>
              <MainLayout>{_data.element}</MainLayout>
            </FolderBoardContextProvider>
          )
        }
      };
    }
    if (_data.useMobileDrawer) {
      _data = {
        ..._data,
        ...{
          element: (
            <>
              {_data.element}
              <MobileDrawer />
            </>
          )
        }
      };
    }
    if (_data.protected) {
      _data = { ..._data, ...{ element: <ProtectedRoute>{_data.element}</ProtectedRoute> } };
    }

    return _data;
  });

  const router = createBrowserRouter(element);

  useEffect(() => {
    const existingUuid = localStorage.getItem(LS_TB_UUID);
    if (!existingUuid) {
      const uuid = uuidv4();
      localStorage.setItem(LS_TB_UUID, uuid);
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <Loading />
        <Errors />
        <Snackbar />
        <Notification />
        <RouterProvider router={router} />
      </AuthContextProvider>
    </QueryClientProvider>
  );
}

export default App;
