import { ReactNode } from 'react';
import create from 'zustand';

export interface useNotificationStoreInterface {
  showNotification: boolean;
  title: string | ReactNode;
  description: string | ReactNode;
  setShowNotification: ({
    isShow,
    title,
    description
  }: {
    isShow: boolean;
    title?: string | ReactNode;
    description?: string | ReactNode;
  }) => void;
}

export const useNotificationStore = create<useNotificationStoreInterface>((set) => ({
  showNotification: false,
  title: '',
  description: '',
  setShowNotification: ({ isShow, title = '', description = '' }) =>
    set({ showNotification: isShow, title, description })
}));
