import ReactGA from 'react-ga4';

import { ActiveTeam } from 'context/AuthContext/AuthContext.interface';

const TRACKING_ID = import.meta.env.VITE_GOOGLE_ANALYTICS_TRACKING_ID ?? '';

function init() {
  // Enable debug mode on the local development environment
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
  ReactGA.initialize(TRACKING_ID, { testMode: isDev });
}

function sendEvent(payload: any) {
  ReactGA.event(payload);
}

function sendPageview(path: string) {
  ReactGA.send({ hitType: 'pageview', page: path });
}

export function userPlanNormalizer(
  eventName: string,
  activeTeam: ActiveTeam | null | undefined,
  userPlan = 'free'
) {
  const isPro = userPlan === 'pro';
  const isTeam = Boolean(activeTeam?.id);
  if (isTeam) {
    return eventName + '_team';
  }

  if (isPro) {
    return eventName + '_pro';
  }

  return eventName;
}

export default {
  init,
  sendEvent,
  sendPageview
};
