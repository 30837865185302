import lazyLoader from 'lib/lazyLoader';

const SubscriptionLoadable = lazyLoader(
  async () => await import(/* webpackChunkName: "team-subscription" */ './team-subscription')
);

const Subscription = (props: any) => {
  return <SubscriptionLoadable {...props} />;
};

export default Subscription;
