import { ChevronRightSolidIcon } from './chevron-right-solid-icon';

type Props = {
  iconColor?: string;
} & JSX.IntrinsicElements['svg'];

export const ChevronDownSolidIcon = (props: Props) => {
  const { iconColor, ...rest } = props;

  return (
    <ChevronRightSolidIcon
      {...rest}
      iconColor={iconColor}
      className={`rotate-90 ${rest.className}`}
    />
  );
};
