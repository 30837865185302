import { UserPlanRoleCodeWithOwner } from 'helpers/constants';

import {
  checkAuth,
  getWithAuth,
  patchJsonAuth,
  postFormDataAuth,
  postJsonAuth,
  prepareOptions
} from '../config';

export interface FolderBoardResponse {
  id: number;
  created_at: string;
  updated_at: string;
  creator_id: number;
  folder_id: number;
  url_id: string;
  type: string;
  order: number;
  text: string;
  name: string;
  parent_id: number;
  role: UserPlanRoleCodeWithOwner; // TODO: cross check
  max_layer_reached: boolean;
  thumbnail_url?: string;
  url?: string;
  have_subfolders?: boolean;
}

export interface GetFolderBoardsResponse {
  data: FolderBoardResponse[];
}

export interface MoveBulkFolderParam {
  folderIds?: number[];
  boardIds?: number[];
  parentFolderId?: number;
  targetFolderId?: number;
  toFirstFolder?: boolean;
  toBoard?: boolean;
}

export const getBoardInSubFolder = async (
  subFolderUrlId: string,
  type: string
): Promise<GetFolderBoardsResponse> => {
  return await fetch(
    `${import.meta.env.VITE_DEV_BASE_URL}/v1/boards/${subFolderUrlId}/boards?type=${type}`,
    prepareOptions()
  ).then(async (res) => {
    if (!res.ok) {
      await checkAuth(res);
      throw await res.json();
    }
    return await res.json();
  });
};

export const getBoardDetail = async (boardUrlId: string) => {
  return await getWithAuth(`${import.meta.env.VITE_DEV_BASE_URL}/v1/boards/${boardUrlId}`);
};

export const updateFolderBoard = async (boardUrlId: string, reqData: JSON) => {
  return await fetch(`${import.meta.env.VITE_DEV_BASE_URL}/v1/boards/${boardUrlId}`, {
    method: 'PATCH',
    ...prepareOptions({ data: JSON.stringify(reqData) })
  }).then(async (res) => {
    if (!res.ok) {
      await checkAuth(res);
      throw await res.json();
    }
    return await res.json();
  });
};

export const createSubFolderBoard = async (subFolderUrlId: string, formData: FormData) => {
  return await postFormDataAuth(
    `${import.meta.env.VITE_DEV_BASE_URL}/v1/boards/${subFolderUrlId}/boards`,
    formData
  );
};

export const updateSubFolderBoard = async (subFolderUrlId: string, reqData: JSON) => {
  return await fetch(`${import.meta.env.VITE_DEV_BASE_URL}/v1/boards/${subFolderUrlId}`, {
    method: 'PATCH',
    ...prepareOptions({ data: JSON.stringify(reqData) })
  }).then(async (res) => {
    if (!res.ok) {
      await checkAuth(res);
      throw await res.json();
    }
    return await res.json();
  });
};

export const moveBoardBulk = async ({
  folderIds,
  boardIds,
  parentFolderId,
  targetFolderId,
  toFirstFolder,
  toBoard
}: MoveBulkFolderParam) => {
  return await postJsonAuth(`${import.meta.env.VITE_DEV_BASE_URL}/v1/boards/bulk-move`, {
    folder_ids: folderIds,
    board_ids: boardIds,
    target_folder_id: targetFolderId,
    target_parent_id: parentFolderId,
    to_first_folder: toFirstFolder,
    to_board: toBoard
  });
};

export const copyBoardBulk = async (
  boardIds: number[],
  parentFolderId: number,
  targetFolderId: number
) => {
  return await postJsonAuth(`${import.meta.env.VITE_DEV_BASE_URL}/v1/boards/bulk-copy`, {
    board_ids: boardIds,
    target_folder_id: targetFolderId,
    target_parent_id: parentFolderId
  });
};

export const deleteBoardBulk = async (boardIds: number[]) => {
  return await fetch(`${import.meta.env.VITE_DEV_BASE_URL}/v1/boards/bulk-delete`, {
    method: 'DELETE',
    ...prepareOptions({
      data: JSON.stringify({
        board_ids: boardIds
      })
    })
  }).then(async (res) => {
    if (!res.ok) {
      await checkAuth(res);
      throw await res.json();
    }
    return await res.json();
  });
};

export const updateBoardOrder = async (boardUrlId: string, order: number) => {
  const reqData = {
    order
  };
  return await patchJsonAuth(
    `${import.meta.env.VITE_DEV_BASE_URL}/v1/boards/${boardUrlId}/order`,
    reqData
  );
};

export interface GetFolderBreadcrumbResponse {
  data: {
    breadcrumbs: Array<{
      board_id: number;
      level: number;
      name: string;
      url_id: string;
    }>;
    root: {
      name: string;
      url_id: string;
    };
  };
}

export const getFolderBreadcrumb = async (
  boardUrlId: string
): Promise<GetFolderBreadcrumbResponse> => {
  return await fetch(
    `${import.meta.env.VITE_DEV_BASE_URL}/v1/boards/${boardUrlId}/breadcrumb`,
    prepareOptions()
  ).then(async (res) => {
    if (!res.ok) {
      await checkAuth(res);
      throw await res.json();
    }
    return await res.json();
  });
};
