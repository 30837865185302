import { useEffect } from 'react';

import { CloseOutlineIcon } from 'components/icons';
import Portal from 'components/portal';

import { useNotificationStore } from 'store/notification';

import t from './notification.translations';

const DIMISSAL_DELAY = 5000;

function Notification() {
  const notification = useNotificationStore((state) => state);

  useEffect(() => {
    if (notification.showNotification) {
      setTimeout(() => {
        notification.setShowNotification({ isShow: false });
      }, DIMISSAL_DELAY);
    }
  }, [notification.showNotification]);

  if (!notification.showNotification) {
    return null;
  }

  return (
    <Portal>
      <div className="fixed top-[24px] right-[24px] z-20 flex p-4 rounded-xl bg-white shadow">
        <svg
          width="46"
          height="46"
          viewBox="0 0 46 46"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="3" y="3" width="40" height="40" rx="20" fill="#FEE2E2" />
          <path
            d="M23.0003 20.5V22.1667M23.0003 25.5H23.0086M17.2268 28.8333H28.7738C30.0568 28.8333 30.8587 27.4444 30.2172 26.3333L24.4436 16.3333C23.8021 15.2222 22.1984 15.2222 21.5569 16.3333L15.7834 26.3333C15.1419 27.4444 15.9438 28.8333 17.2268 28.8333Z"
            stroke="#DC2626"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <rect x="3" y="3" width="40" height="40" rx="20" stroke="#FEF2F2" strokeWidth="6" />
        </svg>
        <div className="ml-3">
          <p className="text-sm text-grey-900 font-semibold">{notification.title || t.title}</p>
          <p className="text-sm">{notification.description || t.description}</p>
        </div>
        <CloseOutlineIcon
          width={20}
          height={20}
          iconColor="#71717A"
          className="cursor-pointer"
          onClick={() => notification.setShowNotification({ isShow: false })}
        />
      </div>
    </Portal>
  );
}

export default Notification;
