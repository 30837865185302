import { useState } from 'react';

import { buttonInterface } from './button.interface';
import './style.scss';

function Button(props: buttonInterface) {
  const [mouseDown, setMouseDown] = useState(false);

  const buttonSolidProp = (type: string) => {
    if (props.disabled) return 'bg-grey-200';
    switch (type) {
      case 'primary':
        return `${mouseDown ? 'bg-stone-blue-click' : 'bg-stone-blue hover:bg-stone-blue-hover'}`;
      case 'info':
        return `${mouseDown ? 'bg-info-click' : 'bg-info hover:bg-info-hover'}`;
      case 'success':
        return `${mouseDown ? 'bg-success-click' : 'bg-success hover:bg-success-hover'}`;
      case 'danger':
        return `${mouseDown ? 'bg-salmon-red-click' : 'bg-salmon-red hover:bg-salmon-red-hover'}`;
      case 'transparent':
        return `${mouseDown ? 'bg-slate-200' : 'bg-white hover:bg-slate-100'}`;
      case 'pro':
        return `bg-salmon-stone-dark`;
      default:
        return `${
          mouseDown ? 'bg-pebble-grey-click' : 'bg-pebble-grey hover:bg-pebble-grey-hover'
        }`;
    }
  };

  const buttonOutlineProp = (type: string) => {
    if (props.disabled) return 'border-grey-100';
    switch (type) {
      case 'primary':
        return `border-stone-blue`;
      case 'info':
        return `border-info`;
      case 'success':
        return `border-success`;
      case 'danger':
        return `border-salmon-red`;
      case 'transparent':
        return `border-transparent`;
      case 'pro':
        return `border-gradient`;
      default:
        return `border-grey-200`;
    }
  };

  const textColorSolidProp = (type: string) => {
    if (props.disabled) return 'text-grey-400 fill-grey-400';
    switch (type) {
      case '':
        return 'text-stone-blue fill-stone-blue';
      case 'transparent':
        return 'text-grey-900 fill-grey-900';
      default:
        return 'text-white fill-white';
    }
  };

  const textColorOutlineProp = (type: string) => {
    if (props.disabled) return 'text-grey-400 fill-grey-400';
    switch (type) {
      case 'primary':
        return 'text-stone-blue fill-stone-blue';
      case 'info':
        return 'text-info fill-info';
      case 'success':
        return 'text-success fill-success';
      case 'danger':
        return 'text-salmon-red fill-salmon-red';
      case 'pro':
        return 'text-transparent fill-transparent';
      default:
        return 'text-grey-900 fill-grey-900';
    }
  };

  return (
    <>
      <button
        type={props.buttonType}
        className={`
        ${props.rounded === false ? 'rounded-lg' : 'rounded-[100px]'} ${
          props.solid || (!props.solid && !props.outlined)
            ? `
          ${buttonSolidProp(props.type || '')}
          ${textColorSolidProp(props.type || '')}
        `
            : ''
        }
        ${
          props.outlined
            ? `
          border-2 border-solid
          ${!props.disabled ? 'hover:bg-slate-100' : ''}
          ${buttonOutlineProp(props.type || '')}
          ${textColorOutlineProp(props.type || '')}
        `
            : ''
        }
        ${props.children ? 'py-2' : 'py-3'} ${props.children ? 'px-4' : 'px-3'}
        animate inline-flex
        transform items-center
        justify-center text-base font-semibold ${mouseDown ? 'scale-95' : 'scale-100'} ${
          props?.className || ''
        }`}
        onMouseDown={() => setMouseDown(true)}
        onMouseUp={() => setMouseDown(false)}
        disabled={props.disabled}
        onClick={props.onClick}
      >
        {props.icon && (
          <span
            className={`
            ${props.outlined && props.type === 'pro' ? 'icon-gradient' : ''}
            ${props.children ? 'mr-1.5' : ''} fill-inherit`}
            style={{ clipPath: `url(#${props.icon?.type.name.toLowerCase()})` }}
          >
            {props.icon}
          </span>
        )}
        <span
          className={`flex w-full items-center justify-center ${
            props.outlined && props.type === 'pro' ? 'text-gradient' : ''
          }`}
        >
          {props.children}
        </span>
      </button>
    </>
  );
}

export default Button;
