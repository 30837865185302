import { Navigate, useSearchParams } from 'react-router-dom';

import { COOKIE_ACCESS_TOKEN } from 'helpers/constants';
import { getCookie } from 'helpers/cookie';

function ProtectedRoute({ children }: { children: any }) {
  const [searchParams] = useSearchParams();

  if (!getCookie(COOKIE_ACCESS_TOKEN) && !searchParams.get('webviewToken')) {
    return <Navigate to={`/login?next-url=${window.location.href}`} replace />;
  }

  return children;
}

export default ProtectedRoute;
