import { TeamRoleCode, UserPlanRoleCode } from 'helpers/constants';

import {
  AssignTeamMembersToFolderParams,
  AssignTeamMembersToFolderResponse
} from 'pages/home/components/popover-folder-access/popover-folder-access.interface';

import { checkAuth, postFormDataAuth, postJsonAuth, prepareOptions } from '../config';

export interface TeamMemberInterface {
  id: number;
  name: string;
  email: string;
  role: UserPlanRoleCode;
  invitation_status?: string;
}

export const getTeamMembers = async (
  teamUrlId: string
): Promise<{ data: TeamMemberInterface[] }> => {
  return await fetch(
    `${import.meta.env.VITE_DEV_BASE_URL}/v1/teams/${teamUrlId}/members`,
    prepareOptions()
  ).then(async (res) => {
    if (!res.ok) {
      await checkAuth(res);
      throw await res.json();
    }
    return await res.json();
  });
};

export const getInvitedTeamMembers = async (
  teamUrlId: string
): Promise<{ data: TeamMemberInterface[] }> => {
  return await fetch(
    `${import.meta.env.VITE_DEV_BASE_URL}/v1/teams/${teamUrlId}/members/invited`,
    prepareOptions()
  ).then(async (res) => {
    if (!res.ok) {
      await checkAuth(res);
      throw await res.json();
    }
    return await res.json();
  });
};

export interface TeamDetail {
  id: number;
  url_id: string;
  owner_id: number;
  on_cancel: boolean;
  on_upgrade: boolean;
  subscription_period: 'monthly' | 'yearly';
  subscription_state: 'active' | 'expired' | '';
  name: string;
  role: TeamRoleCode;
  last_4: string;
  created_at: string;
  updated_at: string;
  expired_at: string;
  started_at: string;
  total_team_member: number;
  next_bill: number;
  yearly_total_paid: number;
}

export const getTeamDetail = async (teamUrlId: string): Promise<{ data: TeamDetail }> => {
  return await fetch(
    `${import.meta.env.VITE_DEV_BASE_URL}/v1/teams/${teamUrlId}`,
    prepareOptions({})
  ).then(async (res) => {
    if (!res.ok) {
      await checkAuth(res);
      throw await res.json();
    }
    return await res.json();
  });
};

export interface TeamList {
  data: TeamDetail[];
}

export const getTeamList = async (): Promise<TeamList> => {
  return await fetch(`${import.meta.env.VITE_DEV_BASE_URL}/v1/teams`, prepareOptions()).then(
    async (res) => {
      if (!res.ok) {
        await checkAuth(res);
        throw await res.json();
      }
      return await res.json();
    }
  );
};

export interface DeleteTeamMemberParams {
  teamUrlId: string;
  userId: number;
}

export const deleteTeamMember = async ({
  teamUrlId,
  userId
}: DeleteTeamMemberParams): Promise<{ message: string }> => {
  return await fetch(
    `${import.meta.env.VITE_DEV_BASE_URL}/v1/teams/${teamUrlId}/members/${userId}`,
    {
      ...prepareOptions({}),
      method: 'DELETE'
    }
  ).then(async (res) => {
    if (!res.ok) {
      await checkAuth(res);
      throw await res.json();
    }
    return await res.json();
  });
};

export interface UpdateTeamDetailParams {
  name: string;
  teamUrlId: string;
}

export const updateTeamDetail = async (
  param: UpdateTeamDetailParams
): Promise<{ message: string }> => {
  return await fetch(`${import.meta.env.VITE_DEV_BASE_URL}/v1/teams/${param.teamUrlId}`, {
    method: 'PATCH',
    ...prepareOptions({ data: JSON.stringify({ name: param.name }) })
  }).then(async (res) => {
    if (!res.ok) {
      throw await res.json();
    }
    return await res.json();
  });
};

export interface UpdateTeamMemberRoleParams {
  teamUrlId: string;
  userId: number;
  role: string;
}

export const updateTeamMemberRole = async (
  param: UpdateTeamMemberRoleParams
): Promise<{ message: string }> => {
  return await fetch(
    `${import.meta.env.VITE_DEV_BASE_URL}/v1/teams/${param.teamUrlId}/members/${param.userId}/role`,
    {
      method: 'PATCH',
      ...prepareOptions({ data: JSON.stringify({ role: param.role }) })
    }
  ).then(async (res) => {
    if (!res.ok) {
      throw await res.json();
    }
    return await res.json();
  });
};

export interface CreateTeamParams {
  name: string;
  stripeSessionId: string;
}

export interface CreateTeamResponse {
  data: {
    id: number;
    url_id: string;
    owner_id: number;
    name: string;
    role: string;
    created_at: string;
    updated_at: string;
  };
}

export const createTeam = async (param: CreateTeamParams): Promise<CreateTeamResponse> => {
  return await postJsonAuth(`${import.meta.env.VITE_DEV_BASE_URL}/v1/teams`, {
    name: param.name,
    stripe_session_id: param.stripeSessionId
  });
};

export const assignTeamMembersToFolder = async (
  payload: AssignTeamMembersToFolderParams
): Promise<AssignTeamMembersToFolderResponse> => {
  return await postJsonAuth(
    `${import.meta.env.VITE_DEV_BASE_URL}/v1/teams/${payload.team_url_id}/assign/access`,
    payload
  );
};

interface DeleteTeamFolderAccess {
  teamUrlId: string;
  userId: number;
  folderId: number;
}

export const deleteTeamFolderAccess = async ({
  teamUrlId,
  userId,
  folderId
}: DeleteTeamFolderAccess): Promise<{ message: string }> => {
  return await fetch(`${import.meta.env.VITE_DEV_BASE_URL}/v1/teams/${teamUrlId}/remove/access`, {
    ...prepareOptions({
      data: JSON.stringify({
        folder_id: folderId,
        user_id: userId
      })
    }),
    method: 'DELETE'
  }).then(async (res) => {
    if (!res.ok) {
      await checkAuth(res);
      throw await res.json();
    }
    return await res.json();
  });
};

export const exportMemberToCsv = async (teamUrlId: string) => {
  return await fetch(
    `${import.meta.env.VITE_DEV_BASE_URL}/v1/teams/${teamUrlId}/members/export`,
    prepareOptions()
  )
    .then(async (res) => {
      if (!res.ok) {
        await checkAuth(res);
        throw await res.json();
      }
      return await res.blob();
    })
    .then((blob) => {
      const file = window.URL.createObjectURL(blob);
      window.location.assign(file);
    });
};

interface Member {
  email: string;
  role: string;
  error?: string;
}

export interface PreviewTeamMemberParam {
  file: File;
  teamUrlId: string;
}

export interface PreviewTeamMemberList {
  add_action_data?: Member[];
  edit_action_data?: Member[];
  delete_action_data?: Member[];
  no_action_data?: Member[];
  token?: string;
}

export interface PreviewTeamMemberResponse {
  data: PreviewTeamMemberList;
}

export const getPreviewTeamMember = async ({
  file,
  teamUrlId
}: PreviewTeamMemberParam): Promise<PreviewTeamMemberResponse> => {
  const formData = new FormData();
  formData.append('file', file);
  return await postFormDataAuth(
    `${import.meta.env.VITE_DEV_BASE_URL}/v1/teams/${teamUrlId}/members/bulk/preview`,
    formData
  );
};

export interface BulkMemberInvitationParam {
  token: string;
  action: string;
  teamUrlId: string;
}

export const submitBulkTeamMemberInvitation = async ({
  token,
  action,
  teamUrlId
}: BulkMemberInvitationParam) => {
  return await fetch(
    `${import.meta.env.VITE_DEV_BASE_URL}/v1/teams/${teamUrlId}/members/bulk/execute`,
    {
      method: 'POST',
      ...prepareOptions({ data: JSON.stringify({ action, token }) })
    }
  ).then(async (res) => {
    if (!res.ok) {
      await checkAuth(res);
      throw await res.json();
    }
    return await res.json();
  });
};

export interface BulkDeleteTeamMemberParams {
  teamUrlId: string;
  emails: string[];
}

export const bulkDeleteTeamMember = async ({
  teamUrlId,
  emails
}: BulkDeleteTeamMemberParams): Promise<{ message: string }> => {
  return await fetch(
    `${import.meta.env.VITE_DEV_BASE_URL}/v1/teams/${teamUrlId}/members/bulk-delete-invitation`,
    {
      ...prepareOptions({}),
      method: 'DELETE',
      body: JSON.stringify({
        emails
      })
    }
  ).then(async (res) => {
    if (!res.ok) {
      await checkAuth(res);
      throw await res.json();
    }
    return await res.json();
  });
};
