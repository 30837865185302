import lazyLoader from 'lib/lazyLoader';

const NotFoundLoadable = lazyLoader(
  async () => await import(/* webpackChunkName: "not-found" */ './not-found')
);

const NotFound = (props: any) => {
  return <NotFoundLoadable {...props} />;
};

export default NotFound;
