import { SubFolder } from 'types/board';
import type { Folder } from 'types/folder';
import create from 'zustand';

export interface GlobalFolder {
  id: number;
  parentId?: number | undefined;
  folderId?: number | undefined;
  folderUrlId: string;
  subFolderSegments: string[]; // [urlId1,urlId2,urlId3]
  name: string;
  urlId: string;
  shared?: boolean; // subfolder cannot have this
  active: boolean;
  isExpanded: boolean;
  isExpandable: boolean;
  order: number;
  teamUrlId: string | undefined; // undefined = personal
  isParent: boolean;
  children: GlobalFolder[];
}

export interface UseGlobalFolderListStore {
  folders: GlobalFolder[];
  getFolderTree: (teamUrlId: string | undefined) => GlobalFolder[];
  concatFolders: (newFolders: Folder[], teamUrlId: string | undefined) => void;
  concatSubFolders: (newSubFolders: SubFolder[], folderUrlId: string) => void;
  patchFolderByUrlId: (patchedFolder: {
    urlId: string;
    name?: string;
    order?: number;
    isExpanded?: boolean;
  }) => void;
  deleteFolderOrSubFolderByUrlId: (folderOrSubFolderUrlId: string) => void;
  deleteFolderOrSubFoldersById: (folderOrSubFolderIds: number[]) => void;
}

export const useGlobalFolderListStore = create<UseGlobalFolderListStore>((set, get) => ({
  folders: [],
  getFolderTree: (teamUrlId) => {
    const mutableFolders: GlobalFolder[] = structuredClone(get().folders);

    mutableFolders.sort((fld1, fld2) => (fld1.order < fld2.order ? -1 : 1));

    // only parent folders is able and need to be filtered based on team
    const parentFoldersBasedOnTeams = mutableFolders
      .filter((f) => f.isParent)
      .filter((f) => f.teamUrlId === teamUrlId);
    const subFolders = mutableFolders.filter((f) => !f.isParent);

    // recursively append childrens until all has been paired
    let mutableSubFolders: GlobalFolder[] = structuredClone(subFolders);

    mutableSubFolders.forEach((flatSubFolder) => {
      mutableSubFolders.forEach((innerSubFolder) => {
        if (flatSubFolder.id === innerSubFolder.parentId) {
          // add the folder identifier - will only contains a tuple of parent and child
          // since the relation of all tree is not yet identified
          innerSubFolder.subFolderSegments.push(flatSubFolder.urlId);

          // mark the parent as expandable
          flatSubFolder.isExpandable = true;

          // push the children to the paired parent
          flatSubFolder.children.push(innerSubFolder);
        }
      });
    });

    // re-map all subfolders to properly adjust the subfolder
    mutableSubFolders.forEach((_) => {
      mutableSubFolders.forEach((innerSubFolder) => {
        const innerParentId = innerSubFolder.parentId;
        const parentSubFolder = mutableSubFolders.find((f) => f.id === innerParentId);
        const parentSubFolderSegments = parentSubFolder?.subFolderSegments ?? [];
        innerSubFolder.subFolderSegments = [
          ...new Set([...innerSubFolder.subFolderSegments, ...parentSubFolderSegments])
        ];
      });
    });

    // prune subfolders which is non parent
    mutableSubFolders = mutableSubFolders.filter((f) => f.parentId === 0);

    // attach parent subfolders to the parent folders
    const finalTree = parentFoldersBasedOnTeams.map((pf) => {
      const childrens = mutableSubFolders.filter((f) => pf.id === f.folderId);
      return {
        ...pf,
        children: childrens
      };
    });

    return finalTree;
  },
  patchFolderByUrlId: (patchedFolder) => {
    const clonedFolders: GlobalFolder[] = structuredClone(get().folders);

    const found = clonedFolders.find((f) => f.urlId === patchedFolder.urlId);

    if (found && typeof patchedFolder.name !== 'undefined') {
      found.name = patchedFolder.name;
    }

    if (found && typeof patchedFolder.isExpanded !== 'undefined') {
      found.isExpanded = patchedFolder.isExpanded;
    }

    if (found && typeof patchedFolder.order !== 'undefined') {
      /**
       * before updating, check all folder with same parents
       */
      const sameLeveledFolders = clonedFolders.filter((f) => {
        // if folder
        if (f.isParent && found.isParent && f.teamUrlId === found.teamUrlId) {
          return true;
        }
        // if subfolder
        if (f.folderUrlId === found.folderUrlId && f.parentId === found.parentId) {
          return true;
        }

        return false;
      });

      /**
       * also reorders the adjacent folders order
       */
      // update the order BUT plus/minus 0.5 to place the item before the existing order
      // plus 0.5 if ordering is moved down, minus 0.5 if ordering is moved up
      const modifier = patchedFolder.order > found.order ? 0.5 : -0.5;
      // tamper temporarily the order with the modifier to place item "in between"
      found.order = patchedFolder.order + modifier;
      // then sort the current orders. the updated order should be on the correct order
      sameLeveledFolders.sort((f1, f2) => (f1.order < f2.order ? -1 : 1));
      // // cleanup the order values to get rid of the .5 and set all other orders in place
      sameLeveledFolders.forEach((f, idx) => {
        f.order = idx + 1;
      });
    }

    set(() => ({ folders: clonedFolders }));
  },
  deleteFolderOrSubFoldersById: (deletedFolderOrSubFolderIds) => {
    set(() => ({
      folders: get().folders.filter((f) => !deletedFolderOrSubFolderIds.includes(f.id))
    }));
  },
  deleteFolderOrSubFolderByUrlId: (deletedFolderOrSubFolderUrlId) => {
    set(() => ({
      folders: get().folders.filter((f) => f.urlId !== deletedFolderOrSubFolderUrlId)
    }));
  },
  concatFolders: (newFolders, teamUrlId) => {
    const folders = [...get().folders, ...newFolders.map(normalizeFolderToGlobalFolder(teamUrlId))];

    // unique-ness filter to avoid duplicates
    const distinctFolders = folders.filter(
      (f, fIdx, fArr) => fIdx === fArr.map((fin) => fin.urlId).indexOf(f.urlId)
    );

    set(() => ({ folders: distinctFolders }));
  },
  concatSubFolders: (newSubFolders, folderUrlId) => {
    const folders = [
      ...get().folders,
      ...newSubFolders.map(normalizeSubFolderToGlobalFolder(folderUrlId))
    ];

    // unique-ness filter to avoid duplicates
    const distinctFolders = folders.filter(
      (f, fIdx, fArr) => fIdx === fArr.map((fin) => fin.urlId).indexOf(f.urlId)
    );

    set(() => ({ folders: distinctFolders }));
  }
}));

const normalizeFolderToGlobalFolder =
  (teamUrlId: string | undefined) =>
  (folder: Folder): GlobalFolder => {
    return {
      id: folder.id,
      urlId: folder.urlId,
      parentId: folder.parentId,
      shared: folder.shared,
      active: folder.active,
      name: folder.name,
      order: folder.order,
      // role: folder.role,
      folderId: folder.id,
      folderUrlId: folder.urlId,
      isParent: true,
      teamUrlId,
      children: [],
      subFolderSegments: [],
      isExpanded: false,
      isExpandable: folder.haveSubfolders
    };
  };

const normalizeSubFolderToGlobalFolder =
  (folderUrlId: string) =>
  (subFolder: SubFolder): GlobalFolder => {
    return {
      id: subFolder.id,
      urlId: subFolder.urlId,
      parentId: subFolder.parentId,
      shared: undefined,
      active: false,
      name: subFolder.name,
      order: subFolder.order,
      // role: subFolder.role,
      folderId: subFolder.folderId,
      subFolderSegments: [subFolder.urlId],
      folderUrlId,
      isParent: false,
      teamUrlId: undefined,
      children: [],
      isExpanded: false,
      isExpandable: subFolder.haveSubfolders
    };
  };
