type Props = {
  iconColor?: string;
} & JSX.IntrinsicElements['svg'];

export const UserCircleSolidIcon = (props: Props) => {
  const { iconColor, ...rest } = props;

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M4.99936 13.7071L4.86532 13.9989L5.07503 14.2422C6.26626 15.624 8.0311 16.5 9.99998 16.5C11.9688 16.5 13.7336 15.624 14.9249 14.2423L15.1346 13.999L15.0005 13.7071C14.1318 11.8154 12.22 10.5 9.99993 10.5C7.77987 10.5 5.86807 11.8153 4.99936 13.7071ZM17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10ZM10 9.5C11.3807 9.5 12.5 8.38071 12.5 7C12.5 5.61929 11.3807 4.5 10 4.5C8.61929 4.5 7.5 5.61929 7.5 7C7.5 8.38071 8.61929 9.5 10 9.5Z"
        fill={iconColor || '#3E5C77'}
        stroke={iconColor || '#3E5C77'}
      />
    </svg>
  );
};
