import lazyLoader from 'lib/lazyLoader';

const TeamSettingsLoadable = lazyLoader(
  async () => await import(/* webpackChunkName: "team-settings" */ './team-settings')
);

const TeamSettings = (props: any) => {
  return <TeamSettingsLoadable {...props} />;
};

export default TeamSettings;
