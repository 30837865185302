import lazyLoader from 'lib/lazyLoader';

const MyAccountLoadable = lazyLoader(
  async () => await import(/* webpackChunkName: "my-account" */ './my-account')
);

const MyAccount = (props: any) => {
  return <MyAccountLoadable {...props} />;
};
export default MyAccount;
