import lazyLoader from 'lib/lazyLoader';

const TeamMemberManagementLoadable = lazyLoader(
  async () =>
    await import(/* webpackChunkName: "team-member-management" */ './team-member-management')
);
const TeamMemberManagement = (props: any) => {
  return <TeamMemberManagementLoadable {...props} />;
};

export default TeamMemberManagement;
