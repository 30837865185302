export type UserPlanCode = 'team' | 'pro' | 'free';

export type TeamRoleCode = 'admin' | 'team_editor' | 'team_performer' | 'team_guest' | 'team_admin';
export type FolderRoleCode = 'editor' | 'can_share' | 'performer';
export type UserPlanRoleCode = TeamRoleCode | FolderRoleCode;

// 'owner' came from BE but not being frequently used as business logic as it is not part of main spec
export type UserPlanRoleCodeWithOwner = UserPlanRoleCode | 'owner';
export type FolderRoleCodeWithOwner = FolderRoleCode | 'owner';

export const LS_USER_EMAIL_AUTH = 'USER_EMAIL_AUTH';
export const LS_ACTIVE_TEAM_ID = 'ACTIVE_TEAM_ID';
export const LS_SHOW_PRO_DIALOG = 'SHOW_PRO_DIALOG';
export const LS_NAVIGATE_TO_TEAM_PLAN_PAYMENT = 'NAVIGATE_TO_TEAM_PLAN_PAYMENT';
export const LS_HAS_RELOADED = 'HAS_RELOADED';
export const LS_TB_UUID = 'TB_UUID';
export const LS_LAST_FOLDER_BEFORE_SEARCH = 'LAST_FOLDER_BEFORE_SEARCH';
export const LS_SEARCH_HISTORY = 'SEARCH_HISTORY';

// hash key used to indicate when the home page is on the new folder creation mode
export const HASH_NEW_FOLDER = 'new-folder';

// cookie keys
export const COOKIE_ACCESS_TOKEN = 'access_token';

// error keys
export const ERROR_CROSS_PLATFORM_LOGIN = 'USER_CROSS-PLATFORM-LOGIN';

/**
 * Query String keys
 *
 * Do NOT change unless you know what you're doing.
 * These params might be used by BE and Apps as well.
 */
export const QS_DEEP_LINK_TYPE = 'deep_link_type';
export const QS_SUBFOLDER_URL_ID_KEY = 'subFolder';
export const QS_BOARD_PREVIEW = 'preview';
export const QS_UPGRADE_FLAG_PRO_PLAN = 'pro_plan';
export const QS_UPGRADE_FLAG_TEAM_PLAN = 'team_plan';
export const QS_CHECKOUT_TEAM_PLAN_OPTION = 'plan_period';
export const QS_INVITATION_PARAM_FOLDER_URL_ID = 'folder_id';
export const QS_INVITATION_PARAM_TEAM_URL_ID = 'team_id';

/**
 * React Query useQuery keys
 *
 * for discoverability and easier to clear keys,
 * it is advised to always use exported name keys here.
 */

export const RQ_CURRENT_USER = 'rq_current_user';
export const RQ_USER_PROFILE = 'rq_user_profile';
export const RQ_TEAM_LIST = (userId: number | null) => ['rq_team_list', userId];
export const RQ_TEAM_DETAIL = (teamId: string) => ['rq_team_detail', teamId];
export const RQ_TEAM_MEMBERS = (teamId: string) => ['rq_team_members', teamId];

export const RQ_PERSONAL_FOLDERS = ['personal-folder-list'];
export const RQ_TEAM_FOLDERS = (teamUrlId: string) => ['team-folder-list', teamUrlId];

export const RQ_FOLDER_BOARD = (arg: {
  folderUrlId: string | undefined;
  subFolderUrlId: string | undefined;
  activeTeamUrlId: string | undefined;
}) => ['rq_folder_boards', arg.folderUrlId, arg.subFolderUrlId, arg.activeTeamUrlId];

/**
 * Internal Page Urls
 */

export const APP_URL_OPEN_IN_APP = 'open-in-app';

/**
 * External Page Urls
 */
export const TEMPLATE_GALLERY_LANDING_PAGE_URL = 'https://help.teamboard.jp/template';
export const INQUIRY_PAGE_URL = 'https://form.teamboard.jp/';
export const CONTACT_PAGE_URL = 'https://lp.teamboard.jp/download';
export const HELP_PAGE_URL = 'https://help.teamboard.jp/';
export const TEAMBOARD_ANDROID_PLAY_STORE_URL =
  'https://play.google.com/store/apps/details?id=jp.ambitiousgroup.teamboard';
export const TEAMBOARD_APPLE_APP_STORE_URL = 'https://apps.apple.com/app/id1665793842';

export const DEEP_LINK_URL_AUTH = 'teamboard://auth';
export const DEEP_LINK_URL_HOME_INDIVIDUAL = 'teamboard://home?type=individual';
export const DEEP_LINK_URL_HOME_TEAM = 'teamboard://home?type=team';
export const DEEP_LINK_URL_INVITATION = 'teamboard://invitation';
export const DEEP_LINK_URL_PRO_APP_SUBSCRIPTION = 'teamboard://home/subscription';
// TODO: update deeplink once app team confirmed
export const DEEP_LINK_URL_LOGIN = 'teamboard://home/login';

/**
 * Google Analytics related constants
 */
export const GA_EVENT_CATEGORIES = {
  SIGNUP: 'Signup',
  LOGIN: 'Login',
  SIDEBAR: 'Sidebar',
  MY_ACCOUNT: 'My Account',
  BOARD_CREATE_EDIT: 'Board create / edit',
  INVITE: 'invitation',
  SHARE_LINKS: 'Share via Links',
  HOME: 'Home',
  TEAM_SETTING: 'Team Setting',
  TEAM_MEMBER_MANAGEMENT: 'Team Member Management',
  ANALYTICS: 'Analytics',
  PRO_PLAN_LOCK_ERROR: 'Pro plan lock error',
  LANDING_PAGE: 'Landing pages'
};

export const GA_EVENT_ACTIONS = {
  SIGNUP_DISPLAY: 'web_signup_display',
  SIGNUP_SUBMIT: 'web_signup_tap_sendEmail',

  LOGIN_DISPLAY: 'web_login_display',
  LOGIN_SUBMIT: 'web_login_tap_sendEmail',

  SIDEBAR_TAP_CREATE_FOLDER: 'web_sidebar_tap_createFolder',
  SIDEBAR_TAP_ACCOUNT_SWITCH: 'web_sidebar_tap_accountSwitch',
  SIDEBAR_TAP_ACCOUNT: 'web_sidebar_tap_account',
  SIDEBAR_TAP_PRO: 'web_sidebar_tap_Pro',
  SIDEBAR_TAP_EACH_FOLDER: 'web_sidebar_tap_eachFolder',
  SIDEBAR_TAP_EDIT_FOLDER: 'web_sidebar_tap_editFolders',

  BOARD_TAP_CREATE_TYPE_IMAGE: 'web_boardCreate_tap_boardtypeImage_image',
  BOARD_TAP_CREATE_TYPE_FILE: 'web_boardCreate_tap_boardtypeImage_file',
  BOARD_TAP_CREATE_TYPE_TEXT: 'web_boardCreate_tap_boardtypeImage_text',
  BOARD_TAP_CREATE_TYPE_SUBFOLDER: 'web_boardCreate_tap_boardtypeImage',
  BOARD_TAP_CREATE_CLOSE: 'web_boardCreate_tap_close',

  INVITATION_TAP_INVITE: 'web_invitation_tap_invite',
  INVITATION_OPEN_LINK_DISPLAY: 'web_openInvitationLink_display',
  INVITATION_ACCEPT_DISPLAY: 'web_acceptInvitationScreen_display',
  INVITATION_ACCEPT_TAP_JOIN: 'web_acceptInvitationScreen_tap_join',

  INVIATION_REJECT_DISPLAY: 'web_rejectInvitationPopup_display',
  INVIATION_REJECT_TAP_JOIN: 'web_rejectInvitationPopup_tap_join',
  INVIATION_REJECT_TAP_NOT_JOIN: 'web_rejectInvitationPopup_tap_notJoin',

  INVITATION_LINK_DISPLAY: 'web_LinkInvitationScreen_display',
  INVITATION_LINK_TAP_REGISTER: 'web_LinkInvitationScreen_tap_register',
  INVITATION_LINK_TAP_LOGIN: 'web_LinkInvitationScreen__tap_login',
  INVITATION_LINK_JOIN_FOLDER: 'web_LinkInvitationScreen_tap_joinFolder',
  INVITATION_LINK_COPY_FOLDER: 'web_LinkInvitationScreen_tap_copyFolder',
  INVITATION_LINK_JOIN_TEAM: 'web_LinkInvitationScreen_tap_joinTeam',
  INVITATION_LINK_TAP_ACTIVATE: 'web_invitation_tap_activateLink',
  INVITATION_LINK_TAP_DEACTIVATE: 'web_invitation_tap_deactivateLink',
  INVITATION_LINK_TAP_COPY: 'web_invitation_tap_link',
  INVITATION_LINK_SWITCH_ROLE: 'web_invitation_tap_role',

  TEAM_LEAVE_POPUP_TAP_CONFIRM: 'web_teamLeavePopup_tap_confirm',
  TEAM_LEAVE_POPUP_TAP_CANCEL: 'web_teamLeavePopup_tap_cancel',

  MY_ACCOUNT_TAP_LOGOUT: 'web_myaccount_tap_logout',
  MY_ACCOUNT_TAP_DELETE_ACCOUNT: 'web_myaccount_tap_deleteAccount',
  MY_ACCOUNT_LOGOUT_POPUP_TAP_CONFIRM: 'web_logoutConformationPopup_tap_logout',
  MY_ACCOUNT_DELETE_ACCOUNT_POPUP_TAP_CONFIRM: 'web_confirmationPopup_tap_deleteAccount',

  TEAM_SETTING_DISPLAY: 'web_teamSetting_tap_display',
  TEAM_SETTING_TAP_PAYMENT_DETAIL: 'web_teamSetting_tap_paymentDetail',
  TEAM_SETTING_TAP_PAYMENT_PLAN: 'web_teamSetting_tap_paymentPlan',
  TEAM_SETTING_TAP_UNSUBSCRIBE: 'web_teamSetting_tap_unsubscribe',
  TEAM_SETTING_TAP_MEMBER_MANAGEMENT: 'web_teamSetting_tap_memberManagement',
  TEAM_SETTING_DELETE_MEMBER_POPUP_TAP_DELETE: 'web_teamSetting_deletePopup_tap_delete',

  TEAM_MEMBER_MANAGEMENT_TAP_ADD_MEMBER: 'web_teamMemberManagement_tap_addmember',
  TEAM_MEMBER_MANAGEMENT_TAP_SERACH_MEMBER: 'web_teamMemberManagement_tap_searchInput',

  ANALYTICS_TAB_MEMBER_DISPLAY: 'web_analytics_display_tab_teamMember',
  ANALYTICS_TAB_BOARD_DISPLAY: 'web_analytics_display_tab_board',
  ANALYTICS_TAP_SELECT_SORT: 'web_analytics_tap_sort',
  ANALYTICS_TAP_SELECT_TERM: 'web_analytics_tap_selectTerm',

  PRO_PLAN_LOCK_ERROR_DISPLAY: 'web_lockErrorDialogue_display',

  PRO_PLAN_LOCK_ERROR_SHEET_DISPLAY: 'web_lockErrorSheet_display',

  OPEN_IN_APP_DISPLAY: 'web_appOpen_display'
};

/**
 * Business related constants
 */
export const FREE_USER_MAX_FOLDER = 3;
export const DEFAULT_FOLDER_NAME = '無題';

export const MOBILE_THRESHOLD = 768;
