import { emailLinkValidation, refreshSession } from 'lib/firebase';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { IErrorResponse } from 'api/response.interface';
import { registerUser, submitTracker } from 'api/services/auth';
import type { RegisterUserParams } from 'api/services/auth';
import { getUserLastVisitedPage } from 'api/services/user';

import {
  COOKIE_ACCESS_TOKEN,
  DEEP_LINK_URL_AUTH,
  ERROR_CROSS_PLATFORM_LOGIN,
  LS_ACTIVE_TEAM_ID,
  LS_TB_UUID
} from 'helpers/constants';
import { setCookie } from 'helpers/cookie';
import detectMobileBrowser from 'helpers/detectMobileBrowser';

import { useErrorStore } from 'store/error';
import { useLoadingStore } from 'store/loading';

type AuthAction = 'login' | 'registration' | '';

const Auth = () => {
  const navigate = useNavigate();
  const { setShowError } = useErrorStore((state) => state);

  const urlSearchParam = new URLSearchParams(window.location.search);
  const authAction = urlSearchParam.get('action') as AuthAction;
  const setShowLoading = useLoadingStore((state) => state.setShowLoading);
  const loginNextUrl = urlSearchParam.get('next_url');
  const platform = urlSearchParam.get('platform');
  const trackingId = urlSearchParam.get('tracking_id');

  useEffect(() => {
    setShowLoading(true);

    const newUuid = uuidv4();
    const uuid = localStorage.getItem(LS_TB_UUID) || newUuid;
    if (platform === 'web') {
      emailLinkValidation()
        .then(async (res) => {
          if (!res.success) {
            // handle login authentication error, expired or invalid link
            if (res.errorCode) {
              setShowError({
                errors: [
                  {
                    code: res.errorCode,
                    message: ''
                  }
                ]
              });
            }
            return navigate('/login', { replace: true });
          }

          if (authAction === 'registration') {
            const authFullname = urlSearchParam.get('fullname') as AuthAction;
            const fullname = authFullname || res.displayName || res.email?.split('@')?.[0] || '';
            const promotionConsent = Number(urlSearchParam.get('promotion_consent') || false);

            await handleRegisterUser({ fullname, promotionConsent });
            return;
          }

          if (authAction === 'login') {
            const newTrackingId = trackingId || uuid;
            localStorage.setItem(LS_TB_UUID, newTrackingId);
            await submitTracker('sign_in_success', newTrackingId);
            return await handleLoginUser();
          }
        })
        .catch(() => {
          return navigate('/login', { replace: true });
        })
        .finally(() => {
          setShowLoading(false);
        });
    } else if (platform === 'app' && detectMobileBrowser()) {
      window.location.replace(DEEP_LINK_URL_AUTH + `?link=${encodeURI(window.location.href)}`);
    } else {
      setShowError({
        errors: [
          {
            code: ERROR_CROSS_PLATFORM_LOGIN,
            message: ''
          }
        ]
      });
      return navigate('/login', { replace: true });
    }
  }, []);

  const handleLoginUser = async () => {
    setShowLoading(false);
    if (loginNextUrl) {
      navigate(loginNextUrl);
      return;
    }

    const res = await getUserLastVisitedPage();
    const data = res?.data;
    if (data) {
      if (data.team_id) {
        localStorage.setItem(LS_ACTIVE_TEAM_ID, String(data.team_id));
      }

      navigate(`/${data.folder_url_id}`);
      return;
    }

    navigate('/');
  };

  const handleRegisterUser = async ({ fullname, promotionConsent }: RegisterUserParams) => {
    try {
      await registerUser({
        fullname,
        promotionConsent
      });
      await refreshSession();

      setShowLoading(false);
      if (loginNextUrl) {
        window.location.assign(loginNextUrl);
      } else {
        window.location.replace('/');
      }
    } catch (error) {
      const errorResponse = error as IErrorResponse<
        'UNAUTHORIZED' | 'USER_ALREADY-EXISTS' | 'SERVER_ERROR'
      >;

      if (errorResponse?.errors?.[0]?.code === 'USER_ALREADY-EXISTS') {
        setShowLoading(false);
        navigate('/');
      } else {
        setShowLoading(false);
        navigate('/login');
        setCookie(COOKIE_ACCESS_TOKEN, '', 0);
      }
    }
  };

  return <div />;
};

export default Auth;
