import lazyLoader from 'lib/lazyLoader';

const LoginLoadable = lazyLoader(
  async () => await import(/* webpackChunkName: "login" */ './login')
);

const Login = (props: any) => {
  return <LoginLoadable {...props} />;
};

export default Login;
