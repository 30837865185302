import Modal from 'components/modal';

import t from './max-upload.translations';

export function MaxUploadImageModal({
  showModal,
  closeModal
}: {
  showModal: boolean;
  closeModal: () => void;
}) {
  return (
    <Modal
      open={showModal}
      onClose={closeModal}
      wrapperProps={{
        className: 'rounded-[30px]'
      }}
    >
      <div className="box-border max-w-[345px] px-4 pb-4 text-center">
        <div className="max-w-[279px] px-1">
          <span className="mt-2 text-base font-semibold text-gray-600">
            {t.maxSizeUpload.imageUpload}
          </span>
        </div>
      </div>
    </Modal>
  );
}

export function MaxUploadFileModal({
  showModal,
  closeModal
}: {
  showModal: boolean;
  closeModal: () => void;
}) {
  return (
    <Modal
      open={showModal}
      onClose={closeModal}
      wrapperProps={{
        className: 'rounded-[30px]'
      }}
    >
      <div className="box-border max-w-[345px] px-4 pb-4 text-center">
        <div className="max-w-[279px] px-1">
          <span className="mt-6 text-base font-semibold text-gray-600">
            {t.maxSizeUpload.fileUpload}
          </span>
        </div>
      </div>
    </Modal>
  );
}
