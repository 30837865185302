import dayjs from 'dayjs';

import { MOBILE_THRESHOLD } from 'helpers/constants';

import { IFilterByDate } from './analytic.interfaces';

export const dateTimeRangeToday = (): IFilterByDate => {
  const startDate = new Date();
  startDate.setHours(0, 0, 0, 0);
  const endDate = new Date();
  endDate.setHours(23, 59, 59, 999);
  return {
    startDate,
    endDate
  };
};

export const filterDateTimeRange = (type: string): IFilterByDate => {
  let startDate = new Date();
  let endDate = new Date();
  const now = new Date();

  switch (type) {
    case 'today':
      // datetime range: 00:00:00 - 23:59:59
      startDate = new Date();
      startDate.setHours(0, 0, 0, 0);
      endDate = new Date();
      endDate.setHours(23, 59, 59, 999);
      break;
    case 'yesterday':
      // datetime range: 00:00:00 - 23:59:59
      startDate = new Date();
      startDate.setDate(startDate.getDate() - 1);
      startDate.setHours(0, 0, 0, 0);
      endDate = new Date();
      endDate.setDate(endDate.getDate() - 1);
      endDate.setHours(23, 59, 59, 999);
      break;
    case 'this_week':
      // datetime range: 00:00:00 - 23:59:59
      startDate = new Date();
      startDate.setDate(startDate.getDate() - startDate.getDay());
      startDate.setHours(0, 0, 0, 0);
      endDate = new Date();
      endDate.setDate(endDate.getDate() - endDate.getDay() + 6);
      endDate.setHours(23, 59, 59, 999);
      break;
    case 'last_week':
      // datetime range: 00:00:00 - 23:59:59
      startDate = new Date();
      startDate.setDate(startDate.getDate() - startDate.getDay() - 7);
      startDate.setHours(0, 0, 0, 0);
      endDate = new Date();
      endDate.setDate(endDate.getDate() - endDate.getDay() - 1);
      endDate.setHours(23, 59, 59, 999);

      break;
    case 'this_month':
      // datetime range: 00:00:00 - 23:59:59
      startDate = new Date(now.getFullYear(), now.getMonth(), 1);
      endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      endDate.setHours(23, 59, 59, 999);
      break;
    case 'last_month':
      // datetime range: 00:00:00 - 23:59:59
      startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
      endDate = new Date(now.getFullYear(), now.getMonth(), 0);
      endDate.setHours(23, 59, 59, 999);

      break;
    case 'this_year':
      // datetime range: 00:00:00 - 23:59:59
      startDate = new Date(now.getFullYear(), 0, 1);
      endDate = new Date(now.getFullYear(), 11, 31);
      // set time to 23:59:59
      endDate.setHours(23, 59, 59, 999);

      break;
    default:
      // datetime range: 00:00:00 - 23:59:59
      startDate = new Date();
      startDate.setHours(0, 0, 0, 0);
      endDate = new Date();
      endDate.setHours(23, 59, 59, 999);
      break;
  }

  return {
    startDate,
    endDate
  };
};

export const getTimeIntervalByType = (type: string): string => {
  switch (type) {
    case 'today':
      return '3hour';
    case 'yesterday':
      return '3hour';
    case 'this_week':
      return '1day';
    case 'last_week':
      return '1day';
    case 'this_month':
      return '2day';
    case 'last_month':
      return '3day';
    case 'this_year':
      return '1month';
    default:
      return '3hour';
  }
};

export const getLabelByTypeAndDateFilter = (type: string, date: Date): string => {
  switch (type) {
    case 'today':
      // formating HH:mm
      return dayjs(date).format('HH:mm');
    case 'yesterday':
      return dayjs(date).format('HH:mm');
    case 'this_week':
      return `${date.getDay() + 1}日`;
    case 'last_week':
      return `${date.getDay() + 1}日`;
    case 'this_month':
      return `${date.getDate()}日`;
    case 'last_month':
      return `${date.getDate()}日`;
    case 'this_year':
      return `${date.getMonth() + 1}月`;
    default:
      return dayjs(date).format('HH:mm');
  }
};

export const dateIsoWithTimeZone = (date: Date): string => {
  return dayjs(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
};

export const labelTrimmer = (label: string, width: number): string => {
  if (label.length <= 12) {
    return label;
  }

  return `${label.substring(0, width < MOBILE_THRESHOLD && width > 0 ? 12 : 20)}...`;
};
