// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { clearIndexedDbPersistence, getFirestore } from 'firebase/firestore';

import { COOKIE_ACCESS_TOKEN, LS_ACTIVE_TEAM_ID, LS_USER_EMAIL_AUTH } from 'helpers/constants';
import { setCookie } from 'helpers/cookie';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export const auth = getAuth(app);

export const emailLinkValidation = async (): Promise<{
  success: boolean;
  displayName?: string;
  email?: string;
  errorCode?: string;
}> => {
  if (isSignInWithEmailLink(auth, window.location.href)) {
    // Get the email if available. This should be available if the user completes
    // the flow on the same device where they started it.
    let email = window.localStorage.getItem(LS_USER_EMAIL_AUTH) || '';

    if (!email) {
      const queryParams = new URLSearchParams(window.location.search);
      email = queryParams.get('email') || '';
    }

    try {
      const result = await signInWithEmailLink(auth, email, window.location.href);
      window.localStorage.removeItem(LS_USER_EMAIL_AUTH);

      const accessToken = await result.user.getIdToken();
      setCookie(COOKIE_ACCESS_TOKEN, accessToken);

      return {
        success: true,
        displayName: result.user.displayName || '',
        email: result.user.email || ''
      };
    } catch (error: any) {
      return { success: false, errorCode: error.code };
    }
  }

  return { success: false };
};

// to handle refresh session manually
// for now it only used when registration because we need to regenerate new token
// but most of the time we don't need to manually refresh the session because firebase will automatically refresh the session
export const refreshSession = async (): Promise<{ success: boolean }> => {
  try {
    const newAccessToken = await auth.currentUser?.getIdToken(true);

    if (newAccessToken) {
      setCookie(COOKIE_ACCESS_TOKEN, newAccessToken);
      return { success: true };
    }

    return { success: false };
  } catch (error) {
    return { success: false };
  }
};

export const handleSignOut = async () => {
  try {
    await auth.signOut();
    await clearIndexedDbPersistence(db);
    setCookie(COOKIE_ACCESS_TOKEN, '', 0);
    localStorage.removeItem(LS_ACTIVE_TEAM_ID);
    return { success: true };
  } catch (error) {
    return { success: false };
  }
};
