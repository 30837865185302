import { useEffect } from 'react';

import Portal from 'components/portal';

import { useSnackbarStore } from 'store/snackbar';

const SNACKBAR_DISMISSAL_DELAY = 5000;

function Snackbar() {
  const snackbar = useSnackbarStore((state) => state);

  useEffect(() => {
    if (snackbar.showSnackbar && snackbar.autoDismiss) {
      setTimeout(() => {
        snackbar.setShowSnackbar({ isShow: false });
      }, SNACKBAR_DISMISSAL_DELAY);
    }
  }, [snackbar.showSnackbar, snackbar.autoDismiss]);

  if (!snackbar.showSnackbar) {
    return null;
  }

  return (
    <Portal>
      <div className="fixed bottom-0 z-20 flex h-0 w-full items-center justify-center">
        <div
          className={`absolute bottom-6 flex animate-slideIn items-center justify-center ${
            snackbar.autoDismiss ? 'rounded-full' : 'rounded-lg'
          } bg-gray-900 py-2 px-6`}
        >
          <span className="text-sm text-white font-bold">{snackbar.content}</span>
        </div>
      </div>
    </Portal>
  );
}

export default Snackbar;
