import { FolderBoardResponse } from 'api/services/folder-board';

import { UserPlanRoleCodeWithOwner } from 'helpers/constants';

export type BoardType = 'subfolder' | 'image' | 'text' | 'pdf' | 'file';

export interface Board {
  id: number;
  folderId: number;
  parentId: number;
  creatorId: number;
  urlId: string;
  type: BoardType;
  text: string;
  name: string;
  order: number;
  maxLayerReached: boolean;
  role: UserPlanRoleCodeWithOwner;
  thumbnailUrl?: string;
  url?: string;
  haveSubfolders: boolean;
}

export interface SubFolder extends Board {
  type: 'subfolder';
}

export const normalizeBoardResponse = (folderBoard: FolderBoardResponse): Board => ({
  id: folderBoard.id,
  urlId: folderBoard.url_id,
  type: sanitizeBoardType(folderBoard.type),
  text: folderBoard.text,
  name: folderBoard.name,
  folderId: folderBoard.folder_id,
  order: folderBoard.order,
  creatorId: folderBoard.creator_id,
  maxLayerReached: folderBoard.max_layer_reached,
  parentId: folderBoard.parent_id,
  role: folderBoard.role,
  thumbnailUrl: folderBoard.thumbnail_url,
  url: folderBoard.url,
  haveSubfolders: folderBoard.have_subfolders ?? false
});

const sanitizeBoardType = (type: string): BoardType => {
  if (type === 'subfolder') return 'subfolder';
  if (type === 'image') return 'image';
  if (type === 'text') return 'text';
  if (type === 'file') return 'file';

  return 'text';
};
