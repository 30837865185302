import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLoadingStore } from 'store/loading';

const FailedPayment: React.FC = () => {
  const navigate = useNavigate();
  const setShowLoading = useLoadingStore((state) => state.setShowLoading);

  useEffect(() => {
    setShowLoading(true);

    navigate('/', { replace: true });

    return () => {
      setShowLoading(false);
    };
  }, []);

  return <div />;
};

export default FailedPayment;
