type Props = {
  iconColor?: string;
} & JSX.IntrinsicElements['svg'];

export const MenuAlt2SolidIcon = (props: Props) => {
  const { iconColor, ...rest } = props;

  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill={iconColor || '#3E5C77'}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.599976 2.00078C0.599976 1.33804 1.13723 0.800781 1.79998 0.800781H16.2C16.8627 0.800781 17.4 1.33804 17.4 2.00078C17.4 2.66352 16.8627 3.20078 16.2 3.20078H1.79998C1.13723 3.20078 0.599976 2.66352 0.599976 2.00078Z"
      />
      <path
        fill={iconColor || '#3E5C77'}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.599976 8.00078C0.599976 7.33804 1.13723 6.80078 1.79998 6.80078H16.2C16.8627 6.80078 17.4 7.33804 17.4 8.00078C17.4 8.66352 16.8627 9.20078 16.2 9.20078H1.79998C1.13723 9.20078 0.599976 8.66352 0.599976 8.00078Z"
      />
      <path
        fill={iconColor || '#3E5C77'}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.599976 14.0008C0.599976 13.338 1.13723 12.8008 1.79998 12.8008H8.99998C9.66272 12.8008 10.2 13.338 10.2 14.0008C10.2 14.6635 9.66272 15.2008 8.99998 15.2008H1.79998C1.13723 15.2008 0.599976 14.6635 0.599976 14.0008Z"
      />
    </svg>
  );
};
