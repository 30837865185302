import { postJsonAuth, prepareOptions } from 'api/config';
import { IErrorResponse } from 'api/response.interface';

interface SendEmailSuccessInterface {
  message: string;
}

export type SendEmailResponse =
  | SendEmailSuccessInterface
  | IErrorResponse<'AUTH_INVALID-SIGN-IN-PARAMS' | 'SERVER_ERROR'>;

export const sendEmailLink = async ({
  email,
  fullname,
  nextUrl,
  promotionConsent,
  action,
  trackingId
}: {
  email: string;
  fullname?: string;
  nextUrl?: string | null;
  promotionConsent?: number | null;
  action: string | 'login' | 'registration';
  trackingId: string | null;
}): Promise<SendEmailResponse[]> => {
  return await fetch(`${import.meta.env.VITE_DEV_BASE_URL}/v1/auth/email-link-signin`, {
    method: 'POST',
    ...prepareOptions(
      {
        data: JSON.stringify({
          email,
          fullname,
          promotion_consent: promotionConsent,
          next_url: nextUrl,
          action
        })
      },
      {
        'Tracking-ID': trackingId
      }
    )
  }).then(async (res) => {
    if (!res.ok) {
      throw await res.json();
    }
    return await res.json();
  });
};

interface RegsiterUserSuccessInterface {
  message: string;
}

export type RegisterUserResponse =
  | RegsiterUserSuccessInterface
  | IErrorResponse<'UNAUTHORIZED' | 'USER_ALREADY-EXISTS' | 'SERVER_ERROR'>;

export interface RegisterUserParams {
  fullname: string;
  promotionConsent: number;
}

export const registerUser = async ({
  fullname,
  promotionConsent
}: RegisterUserParams): Promise<RegisterUserResponse> => {
  return await fetch(`${import.meta.env.VITE_DEV_BASE_URL}/v1/users/registrations`, {
    method: 'POST',
    ...prepareOptions({ data: JSON.stringify({ fullname, promotion_consent: promotionConsent }) })
  }).then(async (res) => {
    if (!res.ok) {
      throw await res.json();
    }
    return await res.json();
  });
};

export const submitTracker = async (eventName: string, trackingId: string) => {
  return await postJsonAuth(
    `${import.meta.env.VITE_DEV_BASE_URL}/v1/track`,
    {
      event_name: eventName,
      metadata: {}
    },
    {
      'Content-Type': 'application/json',
      'Tracking-ID': trackingId
    }
  );
};
