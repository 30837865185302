import Lottie from 'react-lottie-light';

import Portal from 'components/portal';

import KeyboardLoader from 'assets/lotties/white-keyboard.json';

import { useLoadingStore } from 'store/loading';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: KeyboardLoader,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

function Loading() {
  const showLoading = useLoadingStore((state) => state.showLoading);

  if (!showLoading) {
    return null;
  }

  return (
    <Portal>
      <div className="fixed top-0 right-0 z-20 flex h-full w-full items-center justify-center bg-[#70707099]">
        <div className="flex h-12 items-center rounded-[100px] py-3.5 px-4">
          <Lottie options={defaultOptions} height={67} width={64} />
        </div>
      </div>
    </Portal>
  );
}

export default Loading;
