type Props = {
  iconColor?: string;
} & JSX.IntrinsicElements['svg'];

export const ChatAlt2SolidIcon = (props: Props) => {
  const { iconColor, ...rest } = props;

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M9 10.5H8.79289L8.64645 10.6464L6.5 12.7929V11V10.5H6H4C3.17157 10.5 2.5 9.82843 2.5 9V5C2.5 4.17157 3.17157 3.5 4 3.5H11C11.8284 3.5 12.5 4.17157 12.5 5V9C12.5 9.82843 11.8284 10.5 11 10.5H9ZM11.3536 14.6464L11.2071 14.5H11H9.03553L10.0355 13.5H11C13.4853 13.5 15.5 11.4853 15.5 9V7.5H16C16.8284 7.5 17.5 8.17157 17.5 9V13C17.5 13.8284 16.8284 14.5 16 14.5H14H13.5V15V16.7929L11.3536 14.6464Z"
        fill={iconColor || '#3E5C77'}
        stroke={iconColor || '#3E5C77'}
      />
    </svg>
  );
};
