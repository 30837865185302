export const BarChartOptions = {
  cornerRadius: 4,
  indexAxis: 'y' as const,
  elements: {
    bar: {
      borderWidth: 2
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  resizeDelay: 10,
  plugins: {
    legend: {
      display: false
    }
  },
  scales: {
    yAxes: {
      ticks: {
        beginAtZero: true,
        color: '#707070',
        font: {
          family: 'icomoon, Noto Sans JP, sans-serif',
          size: 14
        }
      },
      grid: {
        display: false
      }
    },
    xAxes: {
      ticks: {
        beginAtZero: true,
        color: '#707070',
        font: {
          family: 'icomoon, Noto Sans JP, sans-serif',
          size: 12
        },
        callback: function (value: any) {
          // add comma to number
          return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
      },
      grid: {
        borderDash: [12, 10],
        color: '#cccc'
      }
    }
  }
};

export const BoardBarChartOptions = {
  ...BarChartOptions,
  scales: {
    xAxes: {
      ...BarChartOptions.scales.xAxes,
      ...{
        color: '#69737D'
      }
    },
    yAxes: {
      ...BarChartOptions.scales.yAxes,
      ticks: {
        ...BarChartOptions.scales.yAxes.ticks,
        ...{
          color: '#3E5C77'
        }
      }
    }
  }
};

export const BarChartEmptyOptions = {
  ...BarChartOptions,
  plugins: {
    ...BarChartOptions.plugins,
    tooltip: {
      enabled: false
    }
  },
  scales: {
    xAxes: {
      ...BarChartOptions.scales.xAxes,
      ticks: {
        ...BarChartOptions.scales.xAxes.ticks,
        ...{
          stepSize: 500
        }
      },
      max: 16000
    },
    yAxes: BarChartOptions.scales.yAxes
  }
};

export const BoardBarChartEmptyOptions = {
  ...BoardBarChartOptions,
  plugins: {
    ...BoardBarChartOptions.plugins,
    tooltip: {
      enabled: false
    }
  },
  scales: {
    ...BoardBarChartOptions.scales,
    xAxes: {
      ...BoardBarChartOptions.scales.xAxes,
      ticks: {
        ...BoardBarChartOptions.scales.xAxes.ticks,
        ...{
          stepSize: 500
        }
      },
      max: 16000
    },
    yAxes: {
      ...BoardBarChartOptions.scales.yAxes
    }
  }
};

export const BarChartDetailOptions = (maxData: number) => {
  const maxStep = maxData || 300;
  const stepSize = Math.ceil(maxData / 5);

  return {
    fill: false,
    cubicInterpolationMode: 'monotone',
    tension: 0.4,
    responsive: true,
    maintainAspectRatio: false,
    borderColor: '#3E5C77',
    backgroundColor: '#3E5C77',
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        displayColors: false,
        backgroundColor: (context: any) => {
          if (context?.tooltipItems[0]?.raw >= 100) {
            return '#FFFFFF';
          }
          return '#E4E4E4';
        },
        callbacks: {
          label: function (context: any) {
            if (context.dataset.backgroundColor === '#ABABAB') return 'データがありません';
            return `送信数: ${context.parsed.y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
          },
          labelTextColor: function (context: any) {
            if (context?.raw >= 100) {
              return '#000000';
            }
            return '#0A0A0A';
          },
          value: function () {
            return '';
          },
          title: function () {
            return '';
          }
        }
      }
    },
    scales: {
      yAxes: {
        grid: {
          borderDash: [12, 10],
          color: '#cccc'
        },
        ticks: {
          stepSize
        },
        max: maxStep
      },
      xAxes: {
        grid: {
          display: false
        }
      }
    }
  };
};

export const TableColumnsTopBoard = [
  { title: 'No', value: 'no' },
  { title: 'ボード名', value: 'board' },
  { title: '送信回数', value: 'number' }
];

export const TableColumnsTopMember = [
  { title: 'No', value: 'no' },
  { title: '送信者', value: 'board' },
  { title: '送信数', value: 'number' }
];

export const TabItems = [
  {
    title: 'チームメンバー',
    value: 'people',
    isActive: true
  },
  {
    title: '送信内容',
    value: 'boards'
  }
];

export const SortOptions = [
  {
    title: '送信数(降順)',
    id: 'asc'
  },
  {
    title: '送信数(昇順)',
    id: 'desc'
  }
];

export const FilterOptions = [
  {
    title: '今日',
    id: 'today'
  },
  {
    title: '昨日',
    id: 'yesterday'
  },
  {
    title: '今週',
    id: 'this_week'
  },
  {
    title: '先週',
    id: 'last_week'
  },
  {
    title: '今月',
    id: 'this_month'
  },
  {
    title: '先月',
    id: 'last_month'
  },
  {
    title: '今年',
    id: 'this_year'
  }
];

export const IconLabel = {
  file: '  ',
  image: '  ',
  text: '  '
};
