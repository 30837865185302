import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import Table from 'components/table';

import { IErrorResponse } from 'api/response.interface';
import {
  IAnalyticMembers,
  ITeamAnalyticsResponse,
  getTeamAnalyticsTopBoards
} from 'api/services/team-analytics';

import { useErrorStore } from 'store/error';
import { useLoadingStore } from 'store/loading';

import { TableColumnsTopMember } from '../analytic.constants';
import { IFilterByDate, ITableRowsTopBoard } from '../analytic.interfaces';
import t from '../analytic.translations';

export default function DetailBoardTopMembers({
  teamUrlId,
  boardDetailId,
  filterDate
}: {
  teamUrlId?: string;
  boardDetailId?: string;
  filterDate: IFilterByDate;
}) {
  const [rows, setRows] = useState<ITableRowsTopBoard[]>([]);
  const setShowError = useErrorStore((state) => state.setShowError);
  const setShowLoading = useLoadingStore((state) => state.setShowLoading);

  const queryTopBoard = useQuery({
    queryKey: [
      'rq_team_board_analytics_top_member',
      teamUrlId,
      boardDetailId,
      filterDate.startDate,
      filterDate.endDate
    ],
    queryFn: async () => {
      setShowLoading(true);
      const { startDate, endDate } = filterDate;
      if (teamUrlId === undefined || boardDetailId == null) return;
      const data = await getTeamAnalyticsTopBoards(
        teamUrlId,
        startDate,
        endDate,
        boardDetailId,
        'boards'
      );
      return data;
    },
    enabled: !!teamUrlId && !!boardDetailId,
    select: ({ data }) => data,
    onError: (error: IErrorResponse<string>) => setShowError(error),
    onSettled: () => setShowLoading(false),
    onSuccess: (data: ITeamAnalyticsResponse<IAnalyticMembers>) => {
      const rows = data.analytics.map((item, index) => {
        return {
          no: index + 1,
          board: item.user.name,
          number: `${item.count} ${t.times}`
        };
      });
      setRows(rows);
    }
  });

  useEffect(() => {
    queryTopBoard.refetch();
  }, [filterDate]);

  return (
    <div className="mt-11 max-w-[528px] w-full px-9">
      <div className="text-black leading-7 mb-4 font-bold">{t.senderList}</div>
      <Table columns={TableColumnsTopMember} data={rows} />
    </div>
  );
}
