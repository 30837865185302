import { ReactNode } from 'react';
import create from 'zustand';

export interface useSnackbarStoreInterface {
  autoDismiss: boolean;
  showSnackbar: boolean;
  content: string | ReactNode;
  setShowSnackbar: ({
    isShow,
    content,
    autoDismiss
  }: {
    autoDismiss?: boolean;
    isShow: boolean;
    content?: string | ReactNode;
  }) => void;
}

export const useSnackbarStore = create<useSnackbarStoreInterface>((set) => ({
  autoDismiss: true,
  showSnackbar: false,
  content: '',
  setShowSnackbar: ({ autoDismiss = true, isShow, content = '' }) =>
    set({ autoDismiss, showSnackbar: isShow, content })
}));
