type Props = {
  iconColor?: string;
} & JSX.IntrinsicElements['svg'];

export const CheckOutlineIcon = (props: Props) => {
  const { iconColor, ...rest } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M5 13L9 17L19 7"
        stroke={iconColor || '#3E5C77'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
