import lazyLoader from 'lib/lazyLoader';

const AnalyticsLoadable = lazyLoader(
  async () => await import(/* webpackChunkName: "analytics" */ './analytics')
);

const Analytics = (props: any) => {
  return <AnalyticsLoadable {...props} />;
};

export default Analytics;
