import { useEffect } from 'react';

import Modal from 'components/modal';

import ProQr from 'assets/images/pro-qr.svg';

import { useAnalyticsEventTracker } from 'hooks/useGoogleAnalytics';

import { GA_EVENT_ACTIONS, GA_EVENT_CATEGORIES } from 'helpers/constants';

import { useAuth } from 'context/AuthContext';

import t from './pro-modal.translations';

export default function ProModal({
  showModal,
  closeModal
}: {
  showModal: boolean;
  closeModal: () => void;
}) {
  const gaPlanProEventTracker = useAnalyticsEventTracker(GA_EVENT_CATEGORIES.PRO_PLAN_LOCK_ERROR);
  const { userId } = useAuth();

  useEffect(() => {
    if (showModal) {
      gaPlanProEventTracker(GA_EVENT_ACTIONS.PRO_PLAN_LOCK_ERROR_DISPLAY, userId);
    }
  }, [showModal]);

  return (
    <Modal
      open={showModal}
      onClose={closeModal}
      wrapperProps={{
        className: 'rounded-[30px]'
      }}
    >
      <div className="box-border max-w-[345px] px-4 pb-4 text-center">
        <div className="max-w-[279px] px-1">
          <h3 className="mb-6 text-xl font-bold text-black">{t.title}</h3>
          <span className="mt-6 text-base font-semibold text-gray-600">{t.viewPro}</span>
          <img className="mx-auto mt-2" src={ProQr} alt="ProQr" />
        </div>
      </div>
    </Modal>
  );
}
