import { useQuery } from '@tanstack/react-query';
import jwt_decode from 'jwt-decode';
import { auth, handleSignOut } from 'lib/firebase';
import React, { createContext, useContext, useEffect, useState } from 'react';

import { IErrorResponse } from 'api/response.interface';
import { getUserProfile } from 'api/services/user';

import {
  COOKIE_ACCESS_TOKEN,
  LS_ACTIVE_TEAM_ID,
  LS_TB_UUID,
  RQ_CURRENT_USER
} from 'helpers/constants';
import { setCookie } from 'helpers/cookie';

import { useErrorStore } from 'store/error';

import { ActiveTeam, AuthContextState, AuthData } from './AuthContext.interface';

const initialData: AuthData = {
  isLogin: false,
  uid: '',
  userId: null,
  userPlan: 'free',
  email: '',
  name: '',
  activeTeam: null,
  isFetched: false
};

export const AuthContext = createContext<AuthContextState>({
  ...initialData,
  setActiveTeam: () => {}
});

export const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [authData, setAuthData] = useState(initialData);
  const setShowError = useErrorStore((state) => state.setShowError);

  useQuery({
    queryKey: [RQ_CURRENT_USER],
    queryFn: async () => await getUserProfile(),
    enabled: authData.isLogin,
    refetchOnWindowFocus: false,
    onSuccess: (userDetail) => {
      setAuthData((prev) => ({
        ...prev,
        userId: userDetail.data.id,
        email: userDetail.data.email,
        name: userDetail.data.name,
        userPlan: userDetail.data.plan,
        isLogin: true,
        isFetched: true
      }));
    },
    onError: (error: IErrorResponse<string>) => setShowError(error)
  });

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params?.webviewToken) {
      const decodedToken: any = jwt_decode(params.webviewToken);
      handleSignOut().then((res) => {
        if (res.success) {
          const trackingId = params?.trackingId;
          const uuid = localStorage.getItem(LS_TB_UUID);
          localStorage.setItem(LS_TB_UUID, trackingId || uuid || '');
          setCookie(COOKIE_ACCESS_TOKEN, params.webviewToken);
          setAuthData((prev) => ({
            ...prev,
            userId: decodedToken.user_id,
            email: decodedToken.email,
            name: decodedToken.email,
            isLogin: true,
            isFetched: true
          }));
        }
      });
      return;
    }

    auth.onAuthStateChanged(async (user) => {
      if (user?.emailVerified) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const userId = user.uid;
        const accessToken = await user?.getIdToken();

        setAuthData((prev) => ({
          ...prev,
          isLogin: true,
          isFetched: true,
          uid: userId
        }));
        setCookie(COOKIE_ACCESS_TOKEN, accessToken);
      } else {
        setAuthData({
          isLogin: false,
          uid: '',
          userId: null,
          userPlan: 'free',
          email: '',
          name: '',
          activeTeam: undefined,
          isFetched: true
        });
        setCookie(COOKIE_ACCESS_TOKEN, '', 0);
        localStorage.removeItem(LS_ACTIVE_TEAM_ID);
      }
    });

    // to handle auto refresh token when signed in
    auth.onIdTokenChanged(async (user) => {
      const accessToken = await user?.getIdToken();

      if (accessToken) {
        setCookie(COOKIE_ACCESS_TOKEN, accessToken);
      }
    });
  }, []);

  const setActiveTeam = (activeTeam: ActiveTeam | null | undefined) => {
    setAuthData((prev) => ({ ...prev, activeTeam }));
  };

  return (
    <AuthContext.Provider value={{ ...authData, setActiveTeam }}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  const authData = useContext(AuthContext);

  if (!authData) {
    throw new Error('useAuth should be called inside AUthContextProvider!');
  }

  return authData;
};
