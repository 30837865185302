import create from 'zustand';

interface SubFolder {
  id: number;
  folder_id: number;
}

export interface useInputSubFolderNameStoreInterface {
  selectedSubFolder: SubFolder | null;
  selectedSubFolderName: string;
  selectedSubFolderId: number;
  selectedMaxLayerReached: boolean;
  setSelectedSubFolder: (folderName: SubFolder) => void;
  setSelectedSubFolderName: (folderName: string) => void;
  setSelectedSubFolderId: (folderId: number) => void;
  setSelectedMaxLayerReached: (isReached: boolean) => void;
  showInputSubFolderName: boolean;
  setShowInputSubFolderName: (show: boolean) => void;
  isAddSubFolder: boolean;
  setIsAddSubFolder: (isAdd: boolean) => void;
  setActionAddSubFolder: () => void;
}

export const useInputSubFolderNameStore = create<useInputSubFolderNameStoreInterface>((set) => ({
  selectedSubFolder: null,
  selectedSubFolderName: '',
  selectedSubFolderId: 0,
  selectedMaxLayerReached: false,
  setSelectedSubFolder: (data) => set(() => ({ selectedSubFolder: data })),
  setSelectedSubFolderName: (subFolderName) =>
    set(() => ({ selectedSubFolderName: subFolderName })),
  setSelectedSubFolderId: (subFolderId) => set(() => ({ selectedSubFolderId: subFolderId })),
  setSelectedMaxLayerReached: (isReached) => set(() => ({ selectedMaxLayerReached: isReached })),
  showInputSubFolderName: false,
  setShowInputSubFolderName: (show) => set(() => ({ showInputSubFolderName: show })),
  isAddSubFolder: false,
  setIsAddSubFolder: (isAdd) => set(() => ({ isAddSubFolder: isAdd })),
  setActionAddSubFolder: () => set(() => ({ isAddSubFolder: true, showInputSubFolderName: true }))
}));
