type Props = {
  iconColor?: string;
  clipPathProps?: JSX.IntrinsicElements['clipPath'];
} & JSX.IntrinsicElements['svg'];

export const TemplateSolidIcon = (props: Props) => {
  const { iconColor, ...rest } = props;

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M3.6001 4.79998C3.6001 4.13723 4.13736 3.59998 4.8001 3.59998H19.2001C19.8628 3.59998 20.4001 4.13723 20.4001 4.79998V7.19998C20.4001 7.86272 19.8628 8.39998 19.2001 8.39998H4.8001C4.13736 8.39998 3.6001 7.86272 3.6001 7.19998V4.79998Z"
        fill={iconColor || '#446785'}
      />
      <path
        d="M3.6001 12C3.6001 11.3372 4.13736 10.8 4.8001 10.8H12.0001C12.6628 10.8 13.2001 11.3372 13.2001 12V19.2C13.2001 19.8627 12.6628 20.4 12.0001 20.4H4.8001C4.13736 20.4 3.6001 19.8627 3.6001 19.2V12Z"
        fill={iconColor || '#446785'}
      />
      <path
        d="M16.8001 10.8C16.1374 10.8 15.6001 11.3372 15.6001 12V19.2C15.6001 19.8627 16.1374 20.4 16.8001 20.4H19.2001C19.8628 20.4 20.4001 19.8627 20.4001 19.2V12C20.4001 11.3372 19.8628 10.8 19.2001 10.8H16.8001Z"
        fill={iconColor || '#446785'}
      />
    </svg>
  );
};
