import { GetFoldersResponse } from 'api/service-shared.interface';

import { InputFolderNameInterface } from 'pages/home/components/input-folder/input-folder.interface';

import { checkAuth, prepareOptions } from '../config';

export const getTeamFolders = (teamUrlId: string) => async (): Promise<GetFoldersResponse> => {
  return await fetch(
    `${import.meta.env.VITE_DEV_BASE_URL}/v1/teams/${teamUrlId}/folders?active=true`,
    prepareOptions()
  ).then(async (res) => {
    if (!res.ok) {
      await checkAuth(res);
      throw await res.json();
    }
    return await res.json();
  });
};

export const createTeamFolder =
  (teamUrlId: string) => async (reqData: InputFolderNameInterface) => {
    return await fetch(`${import.meta.env.VITE_DEV_BASE_URL}/v1/teams/${teamUrlId}/folders`, {
      method: 'POST',
      ...prepareOptions({ data: JSON.stringify(reqData) })
    }).then(async (res) => {
      if (!res.ok) {
        await checkAuth(res);
        throw await res.json();
      }
      return await res.json();
    });
  };
