import { FolderResponse } from 'api/service-shared.interface';

import { UserPlanRoleCodeWithOwner } from 'helpers/constants';

export interface Folder {
  id: number;
  urlId: string;
  name: string;
  order: number;
  ownerId: number;
  role: UserPlanRoleCodeWithOwner;
  active: boolean;
  shared: boolean;
  parentId: number;
  haveSubfolders: boolean;
}

export const normalizeFolderResponse = (folderResponse: FolderResponse): Folder => ({
  id: folderResponse.id,
  urlId: folderResponse.url_id,
  name: folderResponse.name,
  order: folderResponse.order,
  ownerId: folderResponse.owner_id,
  role: folderResponse.role,
  active: folderResponse.active,
  shared: folderResponse.shared,
  parentId: folderResponse.parent_id,
  haveSubfolders: folderResponse.have_subfolders
});
