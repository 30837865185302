import {
  DocumentTextOutlineIcon,
  FolderOpenOutlineIcon,
  MenuAlt2SolidIcon,
  PhotographOutlineIcon
} from 'components/icons';

const BoardIcon = ({
  boardType,
  className,
  width = 30,
  height = 30
}: {
  boardType: string;
  className: string;
  width?: number;
  height?: number;
}) => {
  const rest = {
    width,
    height
  };
  switch (boardType) {
    case 'file':
      return <DocumentTextOutlineIcon className={className} {...rest} />;
    case 'image':
      return <PhotographOutlineIcon className={className} {...rest} />;
    case 'text':
      return <MenuAlt2SolidIcon className={className} {...rest} />;
    case 'subfolder':
      return <FolderOpenOutlineIcon className={className} {...rest} />;
    default:
      return <></>;
  }
};

export default BoardIcon;
