type Props = {
  iconColor?: string;
} & JSX.IntrinsicElements['svg'];

export const ExclamationCircleSolidIcon = (props: Props) => {
  const { iconColor, ...rest } = props;

  return (
    <svg
      width="61"
      height="60"
      viewBox="0 0 61 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.5 30C54.5 43.2548 43.7548 54 30.5 54C17.2452 54 6.5 43.2548 6.5 30C6.5 16.7452 17.2452 6 30.5 6C43.7548 6 54.5 16.7452 54.5 30ZM33.5 42C33.5 43.6569 32.1569 45 30.5 45C28.8431 45 27.5 43.6569 27.5 42C27.5 40.3431 28.8431 39 30.5 39C32.1569 39 33.5 40.3431 33.5 42ZM30.5 15C28.8431 15 27.5 16.3431 27.5 18V30C27.5 31.6569 28.8431 33 30.5 33C32.1569 33 33.5 31.6569 33.5 30V18C33.5 16.3431 32.1569 15 30.5 15Z"
        fill={iconColor || '#FC8E7C'}
      />
    </svg>
  );
};
