import { AuthErrorCodes } from 'firebase/auth';

import Button from 'components/button';
import Modal from 'components/modal';

import { ERROR_CROSS_PLATFORM_LOGIN, LS_HAS_RELOADED } from 'helpers/constants';

import { useErrorStore } from 'store/error';

import AuthModal from './auth-modal';
import t from './global-error.translations';
import { MaxUploadFileModal, MaxUploadImageModal } from './max-upload';
import NonTeamUserView from './non-team-user-view';
import ShowProModal from './pro-modal';
import TeamPaymentExpiredModal from './team-payment-expired-modal';

const authenticationError: string[] = [
  AuthErrorCodes.INVALID_OOB_CODE,
  AuthErrorCodes.EXPIRED_OOB_CODE,
  ERROR_CROSS_PLATFORM_LOGIN
];

function Errors() {
  const showError = useErrorStore((state) => state.showError);
  const errorCode = useErrorStore((state) => state.code);
  const errorTitle = useErrorStore((state) => state.title);
  const errorMessage = useErrorStore((state) => state.message);

  const setCloseError = useErrorStore((state) => state.setCloseError);

  const tokenIsExpired = errorCode === 'TOKEN_EXPIRED';

  const boardLimitError = [
    'BOARD_LIMIT-REACHED',
    'BOARD_LIMIT-REACHED-PRO', // TODO: not used?
    'BOARD_FILE-LIMIT-REACHED',
    'BOARD_IMAGE-LIMIT-REACHED'
  ];

  if (errorCode === 'TOKEN_EXPIRED') {
    if (!localStorage.getItem(LS_HAS_RELOADED)) {
      localStorage.setItem(LS_HAS_RELOADED, '1');
      window.location.reload();
    } else {
      localStorage.removeItem(LS_HAS_RELOADED);
    }
  } else if (!localStorage.getItem(LS_HAS_RELOADED)) {
    localStorage.removeItem(LS_HAS_RELOADED);
  }

  if (!showError || tokenIsExpired) return <></>;

  if (boardLimitError.includes(errorCode)) {
    return <ShowProModal showModal={showError} closeModal={setCloseError} />;
  }

  if (errorCode === 'BOARD_IMAGE-SIZE-TOO-LARGE') {
    return <MaxUploadImageModal showModal={showError} closeModal={setCloseError} />;
  }

  if (errorCode === 'BOARD_FILE-SIZE-TOO-LARGE') {
    return <MaxUploadFileModal showModal={showError} closeModal={setCloseError} />;
  }

  if (errorCode === 'USER-NO-TEAM-ACCESS') {
    return <NonTeamUserView />;
  }

  if (errorCode === 'TEAM_EXPIRED') {
    return <TeamPaymentExpiredModal showModal={showError} closeModal={setCloseError} />;
  }

  if (authenticationError.includes(errorCode)) {
    return (
      <AuthModal
        errorCode={errorCode}
        showModal={showError}
        closeModal={() => {
          if (errorCode === ERROR_CROSS_PLATFORM_LOGIN) {
            window.location.assign('/login');
          } else {
            setCloseError();
          }
        }}
      />
    );
  }

  return (
    <Modal hideCloseIcon={true} open={showError} onClose={setCloseError}>
      <div className="flex min-w-[293px] flex-col items-center justify-center lg:min-w-[345px]">
        <div className="text-center text-2xl font-bold">{errorTitle || t.errorTitle}</div>
        <div className="mt-2 text-center text-base">{errorMessage || t.errorSubtitle}</div>
        <div className="mt-6 flex justify-center">
          <Button onClick={setCloseError}>{t.errorCta}</Button>
        </div>
      </div>
    </Modal>
  );
}

export default Errors;
